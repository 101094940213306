import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Cartonization.frontend.types'

@Injectable({ providedIn: 'root' })
export class Cartonization_create_shipping_containers_for_shipment_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { shipmentId: number, containers: { index?: number, parentIndex?: number, containerTypeId?: number, length?: number, width?: number, height?: number, dimensionsUomId?: number, volumeUomId?: number, weight?: number, weightUomId?: number, shipmentId?: number, shipmentLines?: { id?: number, takeBaseAmount?: number, tasks?: { id?: number, takeBaseAmount?: number }[] }[], inventoryMeasurementUnitId?: number, existingContainerIds?: number[] }[], runAsBatch?: boolean }): Promise<{ batchEntities?: { method?: string, set?: string, keys?: { name?: string, value?: any }[], properties?: any }[], reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.containers)) {
      missingRequiredInParams.push('\'containers\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

