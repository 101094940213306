import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ViewChild
} from '@angular/core';

import { DropdownSingleComponent } from './components/dropdown-single.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-home',
  templateUrl: './app.home.component.html'
})
export class app_homeComponent implements OnInit {

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  toolbar = {
  };


  fieldsets = {
  };

  @ViewChild('dd_config') dd_config: DropdownSingleComponent;
  @ViewChild('dd_method') dd_method: DropdownSingleComponent;

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) { 

  }

  ngOnInit(): void {
    this.$init();
  
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
    this.initialized = true;
  }

  close() {
    this.$finish.emit();
  }

  typeValue = null;
  typeList = [
    {
      key: 6,
      name: 'Datasource'
    },
    {
      key: 9,
      name: 'Function'
    }
  ];

  async typeDisplayWithFn(value: any): Promise<string> {
    return this.typeList.find(item => item.key === value)?.name;
  }

  async typeOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    if (this.utils.isDefinedTrimmed(filterText)) {
      return { list: this.typeList.filter(item => item.name.startsWith(filterText)), totalCount: this.typeList.length };
    } else {
      return { list: this.typeList, totalCount: this.typeList.length };
    }
  }

  typeValueChange(value) {
    this.typeValue = value;
    this.configValueChange(null);
    this.dd_config.clearListData();
  }


  configValue = null;
  config = null;
  configList = [
    {
      key: 'app/functions/create_shipment_attachment',
      name: 'app/functions/create_shipment_attachment',
      referenceName: 'create_shipment_attachment',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'file_content',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'shipment_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/create_shipping_container_attachment',
      name: 'app/functions/create_shipping_container_attachment',
      referenceName: 'create_shipping_container_attachment',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'file_content',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'FootPrintApiManager/functions/alerts_send',
      name: 'FootPrintApiManager/functions/alerts_send',
      referenceName: 'custom_alerts_send',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'log_level',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'log_input',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'log_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'FootPrintApiManager/functions/get_report_data_flow',
      name: 'FootPrintApiManager/functions/get_report_data_flow',
      referenceName: 'custom_get_report_data_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'package_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'report_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'inputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/custom_submit_hoplite_inbound_receipt',
      name: 'app/functions/custom_submit_hoplite_inbound_receipt',
      referenceName: 'custom_submit_hoplite_inbound_receipt',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/print_cloud_report',
      name: 'app/functions/print_cloud_report',
      referenceName: 'print_cloud_report',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'packageName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportInputs',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'printerName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/process_return_order_flow',
      name: 'app/functions/process_return_order_flow',
      referenceName: 'process_return_order_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/transfer_licenseplates_to_new_salesorder_flow',
      name: 'app/functions/transfer_licenseplates_to_new_salesorder_flow',
      referenceName: 'transfer_licenseplates_to_new_salesorder_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'Cartonization/functions/cartonize_shipment_flow',
      name: 'Cartonization/functions/cartonize_shipment_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'cartonize_shipment_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'Cartonization/functions/palletize_shipment_flow',
      name: 'Cartonization/functions/palletize_shipment_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'palletize_shipment_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'FootPrintApiManager/functions/insert_log',
      name: 'FootPrintApiManager/functions/insert_log' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'insert_log',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'level',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'process',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'InventoryTransfers/functions/execute_inventory_transfer_flow',
      name: 'InventoryTransfers/functions/execute_inventory_transfer_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'execute_inventory_transfer_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferId',
            type: 'number',
            isCollection: false
          },
        ]
    },
  ];

  async configDisplayWithFn(value: any): Promise<string> {
    return this.configList.find(item => item.key === value)?.name;
  }

  async configOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.configList.filter(item => item.type === this.typeValue);
    const totalCount = list.length;
    if (this.utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  configValueChange(value) {
    this.configValue = value;
    this.config = this.configList.find(item => item.key === value);
    this.methodValueChange(null);
    this.dd_method.clearListData();
  }

  get hasMethods():boolean {
    return this.config && this.config?.methods;
  }
  
  method = null;
  methodValue = null;

  async methodDisplayWithFn(value: any): Promise<string> {
    return value;
  }

  async methodOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.config.methods;
    const totalCount = list.length;
    if (this.utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  methodValueChange(value) {
    this.methodValue = value;
    this.result = null;    
    if (this.hasMethods) {
      this.method = this.config.methods.find(item => item.key === value);
    } else {
      this.method = null;
    }
    this.onInParamChanged();
  }


  get inParams() {
    if (this.config) {
      if (this.config.methods) {
        if (this.method) {
          return this.method.inParams;
        }
      } else {
        return this.config.inParams;
      }
    }
    return null;
  }

  previewInParams = null;
  onInParamChanged() {
    this.previewInParams = {};
    if (this.inParams && this.inParams.length) {
      const inParams: { [key: string]: any } = {};
      this.inParams.forEach(ip => {
        inParams[ip.id] = this.evaluateValue(ip.value);
      });
      this.previewInParams = inParams;
    }
  }

  evaluateValue(value: string) {
    const val = this.utils.isDefinedTrimmed(value) ? `(${value})` : value;
    const f = new Function(`{ return ${val} }`);
    return f();
  }

  get canExecute (): boolean {
    if (!this.config) {
      return false;
    }
    if (this.config.type === 6 && !this.methodValue) {
      return false;
    } 
    return true;
  }

  result: any;
  async run() {
    if (this.config.type === 6) {
      this.result = await this.datasources[this.config.appReferenceName][this.config.referenceName][this.methodValue](this.previewInParams);
    } else if (this.config.type === 9) {
      this.result = await this.flows[this.config.appReferenceName][this.config.referenceName](this.previewInParams);
    }
  }  
}
