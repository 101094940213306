import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, ContentChild, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { Subscription, merge, Observable } from 'rxjs';
import { delay, switchMap, take, tap } from 'rxjs/operators';
import { DOWN_ARROW, ENTER, ESCAPE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { UtilsService } from '../utils.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Styles } from '../models/style';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-dropdown-single',
  templateUrl: './dropdown-single.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSingleComponent),
      multi: true
    }
  ]
})
export class DropdownSingleComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder = '';
  @Input()
  displayWith: ((value: any) => Promise<string>) | null

  @Input()
  options: ((filterText: string) => Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>) | null

  @Input() styles: Styles;
  @Input() tooltip = '';

  @Input()
  newItemLabel: string;;
  @Output()
  newItemClick = new EventEmitter<void>();

  @ViewChild('trigger') triggerInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.triggerInput?.nativeElement?.focus();
  }

  hasSelectedItem = false;

  filteredOptions: Array<{ key: any; name: string; disabled?: boolean; }> = [];
  filterText: string = '';
  filterTextChanged: boolean = true;
  hasOpenedPanel = false;
  triggerInputText = '';

  originalDiplayTexts = '';

  _innerValue: any;
  disabled: boolean;

  constructor(
    private utils: UtilsService,
    private _elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.filteredOptions = [];
    this.filterText = '';
    this.filterTextChanged = true;
  }


  updateTexts(diplayTexts: string) {
    // console.log('updateTexts');
    if (this.triggerInput) {
      this.triggerInput.nativeElement.value = diplayTexts;
      this.triggerInputText = this.triggerInput.nativeElement.value;
    }
  }

  _handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;

    // Prevent the default action on all escape key presses. This is here primarily to bring IE
    // in line with other browsers. By default, pressing escape on IE will cause it to revert
    // the input value to the one that it had on focus, however it won't dispatch any events
    // which means that the model value will be out of sync with the view.
    if (keyCode === ESCAPE) {
      event.preventDefault();
    }

    if (this.trigger.activeOption && (keyCode === ENTER || keyCode === TAB) && this.trigger.panelOpen) {
      this.optionSelected(this.trigger.activeOption.value);
      if (keyCode === TAB) {
        this.trigger.closePanel();
      } else {
        event.preventDefault();
      }
    } else if (this.trigger.autocomplete) {
      const prevActiveItem = this.trigger.autocomplete._keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (this.trigger.panelOpen || keyCode === TAB) {
        this.trigger.autocomplete._keyManager.onKeydown(event);
      } else if (isArrowKey) {
        if (!this.disabled && !this.trigger.panelOpen) {
          this.filterAndOpenPanel();
        }
      }

      if (isArrowKey || this.trigger.autocomplete._keyManager.activeItem !== prevActiveItem) {
        // There seem to be a bug where the panel is undefined
        // when you have the panel opened then scroll the host which closes
        // the panel and focus is still in the dropdown then when you use the arrow key
        // it tries to scrolltooption
        // but the panel is undefined for some reason (maybe a bug from material)
        // and it blows, even though the panel has been opened
        // by the code above -> else if (isArrowKey) { openPanel }
        if (this.trigger.autocomplete.panel) {
          (this.trigger as any)._scrollToOption(this.trigger.autocomplete._keyManager.activeItemIndex || 0);
        }
      }
    }
  }

  _handleInput(event: KeyboardEvent): void {
    // console.log('_handleInput');
    // prevent trigger behavior
    // do nothing
  }

  _setValueAndClose(event: any | null): void {
    // console.log('_setValueAndClose', event);
    // override trigger behavior

    if (event && event.source) {
      this.focus();
      return;
    }
    this.trigger.closePanel();
  }

  _subscribeToClosingActions(): Subscription {
    // override trigger behavior
    const firstStable = (this.trigger as any)._zone.onStable.asObservable().pipe(take(1));
    const optionChanges = this.trigger.autocomplete.options.changes.pipe(
      tap(() => (this.trigger as any)._positionStrategy.reapplyLastPosition()),
      // Defer emitting to the stream until the next tick, because changing
      // bindings in here will cause "changed after checked" errors.
      delay(0)
    );

    // When the zone is stable initially, and when the option list changes...
    return merge(firstStable, optionChanges)
      .pipe(
        // create a new stream of panelClosingActions, replacing any previous streams
        // that were created, and flatten it so our stream only emits closing events...
        switchMap(() => {
          const wasOpen = this.trigger.panelOpen;
          (this.trigger as any)._resetActiveItem();
          this.trigger.autocomplete._setVisibility();

          if (this.trigger.panelOpen) {
            // tslint:disable-next-line:no-non-null-assertion
            (this.trigger as any)._overlayRef!.updatePosition();

            // If the `panelOpen` state changed, we need to make sure to emit the `opened`
            // event, because we may not have emitted it when the panel was attached. This
            // can happen if the users opens the panel and there are no options, but the
            // options come in slightly later or as a result of the value changing.
            if (wasOpen !== this.trigger.panelOpen) {
              this.trigger.autocomplete.opened.emit();
            }
          }

          return this.trigger.panelClosingActions;
        }))
      // set the value, close the panel, and complete.
      .subscribe(event => this._setValueAndClose(event));
  }

  ngAfterViewInit() {

    this.trigger._handleKeydown = this._handleKeydown.bind(this);
    this.trigger._handleInput = this._handleInput.bind(this);
    (this.trigger as any)._subscribeToClosingActions = this._subscribeToClosingActions.bind(this);
    (this.trigger as any)._setValueAndClose = this._setValueAndClose.bind(this);

  }

  ngOnDestroy() {
  }

  onValueChanged(val: any) {
    // console.log('on dropdown ui value changed');
    if (this.value !== val) {
      this.value = val;
      this.reactOnValueChange(this.value);
    }
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  public onKeyUp($event: KeyboardEvent) {
    if (!this.disabled) {

      // when user start entering keys on keyboard
      // check if the entered text is different from last saved text
      // then perform open the panel and filter
      if (this.triggerInput.nativeElement.value !== this.triggerInputText) {
        this.applyFilterText(this.triggerInput.nativeElement.value);
        this.triggerInputText = this.triggerInput.nativeElement.value;
        this.filterAndOpenPanel();
      }
    }
  }

  public onFocus($event: any) {
    //TODO:focus    
    //this.focusIn();
  }

  public onBlur($event: any) {
    this.updateTexts(this.originalDiplayTexts);
    this.applyFilterText('');
    //TODO:focus
    //this.focusOut();
  }

  public onClick(e: any) {
    if (!this.disabled && !this.trigger.panelOpen) {
      this.filterAndOpenPanel();
    }
  }

  // command buttons
  togglePanel(e: any) {
    // return focus to trigger
    this.triggerInput.nativeElement.focus();
    if (this.trigger.panelOpen) {
      this.trigger.closePanel();
    } else {
      if (!this.disabled) {
        this.filterAndOpenPanel();
      }
    }
  }

  clearSelectedItem() {
    // return focus to trigger
    this.triggerInput.nativeElement.focus();
    this.onValueChanged(null);
  }

  public filterAndOpenPanel() {
    if (this.filterTextChanged === true) {
      //Logger.debug(LoggerDebugType.CONTROL_DROPDOWN, 'filterItems with :', this.viewModel.filterText);
      if (!this.trigger.panelOpen) {
        this.trigger.openPanel();
      }

      this.reactFilterTextChange(this.filterText);

    } else {
      if (!this.trigger.panelOpen) {
        //Logger.debug(LoggerDebugType.CONTROL_DROPDOWN, 'filterItems just open');
        this.trigger.openPanel();
      }
    }
  }

  public displayFn(item?: { key: any; name: string; disabled?: boolean; }): string | undefined {
    return item ? item.name : undefined;
  }

  optionSelected(item: { key: any; name: string; disabled?: boolean; }) {
    if (item.disabled) {
      return;
    }
    this.onValueChanged(item.key);
    this.trigger.closePanel();
  }

  //view model
  applyFilterText(newFilterText: string) {
    if (newFilterText !== this.filterText) {
      this.filterText = newFilterText;
      this.filterTextChanged = true;
    }
  }

  updateDisplayTexts(displayText: string) {
    this.applyFilterText('');
    this.originalDiplayTexts = displayText;
    this.updateTexts(displayText);
  }

  public setDisplay(displayText: string) {
    this.hasSelectedItem = true;
    this.updateDisplayTexts(displayText);
  }

  public clearDisplay() {
    this.hasSelectedItem = false;
    this.updateDisplayTexts('');
  }

  public setListData(list: Array<{ key: any; name: string; disabled?: boolean; selected?: boolean }>) {
    if (this._innerValue) {
      // Find the selected item
      const selectedItemIndex = list.findIndex(item => isEqual(item.key, this._innerValue));
      if (selectedItemIndex !== -1) {
        // Move the selected item to the top
        const [selectedItem] = list.splice(selectedItemIndex, 1);
        list.unshift(selectedItem);
      }
    }
    this.filteredOptions = list;
    this.filterTextChanged = false;
  }

  public clearListData() {
    this.filterTextChanged = true;
    this.filteredOptions = [];
  }

  public reactOnValueChange(value: any) {
    if (this.utils.isDefined(value)) {
      this.displayWith(value).then(result => {
        if (this.utils.isDefined(result)) {
          this.setDisplay(result);
        } else {
          this.clearDisplay();
        }
      });
    } else {
      this.clearDisplay();
    }
  }

  public reactFilterTextChange(filterText: string) {
    this.setListData([{ key: null, name: "Loading...", disabled: true }]);
    this.options(filterText).then(async result => {
      let optionList = result.list;

      if (optionList.length === 0) {
        optionList.push({ key: null, name: "No data to display", disabled: true });
      } else if (this.utils.isDefined(result.totalCount)) {
        if (result.totalCount > optionList.length) {
          const a = optionList.length.toString() + " of " + result.totalCount.toString() +
            " matches shown. Please refine your criteria.";
          optionList.push({ key: null, name: a, disabled: true });
        }
      } else if (this.utils.isDefined(result.top)) {
        if (result.top >= optionList.length) {
          const a = "More than " + (result.top - 1).toString() +
            " matches found. Please refine your criteria.";
          optionList.pop();
          optionList.push({ key: null, name: a, disabled: true });
        }
      }

      // Include the selected item if it's not in the optionList
      if (this._innerValue) {
        const selectedItemIndex = optionList.findIndex(item => isEqual(item.key, this._innerValue));
        if (selectedItemIndex === -1) {
          // Selected item is not in the optionList
          const displayText = await this.displayWith(this._innerValue);
          if (this.utils.isDefined(displayText)) {
            const selectedItem = { key: this._innerValue, name: displayText, disabled: false };
            optionList.unshift(selectedItem); // Add selected item to the top
          }
        } else {
          // Move the selected item to the top
          const [selectedItem] = optionList.splice(selectedItemIndex, 1);
          optionList.unshift(selectedItem);
        }
      }

      this.setListData(optionList);

      // Set the first item as active using timeout (only working solution found without [autoActiveFirstOption])
      // if (this.trigger.autocomplete._keyManager && this.trigger.panelOpen) {
      //   setTimeout(() => {
      //     this.trigger.autocomplete._keyManager.setFirstItemActive();
      //   }, 100);
      // }

    });
  }

  //ControlValueAccessor
  onChange: any = () => { };
  onTouch: any = () => { };

  get value() {
    return this._innerValue;
  }

  set value(val) {
    if (this.value !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
    this.reactOnValueChange(value);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onNewItemClicked() {
    this.trigger.closePanel();
    this.newItemClick.emit();
  }

  opened() {
    this.hasOpenedPanel = true;
    this.triggerInput.nativeElement.value = this.filterText;
    this.triggerInputText = this.filterText;
  }

  closed() {
    this.hasOpenedPanel = false;
    this.applyFilterText('');
    this.updateTexts(this.originalDiplayTexts);
  }
}
