import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_ds_get_shipment_by_shipmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number }): 
  Promise<{ result: { Id?: number, CarrierServiceTypeId?: number, ContainerSize?: string, SpecialPalletHandling?: string, Carrier?: { Id?: number, Services?: { ServiceTypeId?: number, Service_Type?: string }[] }, OrderLookups?: { OrderId?: number, Order?: { LookupCode?: string, PreferredCarrierServiceTypeId?: number, PreferredCarrier?: { Id?: number, Services?: { ServiceTypeId?: number, Service_Type?: string }[] } } }[], OrderLines?: { LineNumber?: number, Material?: { Id?: number, MaterialGroup?: { Id?: number, Name?: string } } }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentId: number    }) {
        if(isNil(inParams.shipmentId)) {
          return true; 
        }
      return false;
    }
}
