import { Inject, Injectable, Injector }from '@angular/core';


import { Reports_custom_ds_custom_receiving_report_dataLoggersService } from './Reports.datasource.index';
import { Reports_custom_ds_cycle_count_report_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_cycle_count_report_recount_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_dock_appointment_details_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_get_deliver_to_country_by_shipmentIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_dock_appointment_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_account_address_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_address_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_ship_to_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_sscc_label_typeService } from './Reports.datasource.index';
import { Reports_custom_ds_get_price_orderline_materialService } from './Reports.datasource.index';
import { Reports_custom_ds_get_serial_numbers_by_pallet_idService } from './Reports.datasource.index';
import { Reports_custom_ds_get_shipping_containers_tasksService } from './Reports.datasource.index';
import { Reports_custom_ds_get_survey_answer_driver_photo_by_appointmentIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_survey_answer_first_name_by_appointmentIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_survey_answer_seal_number_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_survey_answer_trailer_number_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_licenseplate_details_by_serialIdService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_label_by_shippingContainerIdService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_get_order_address_by_orderId_nameService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_get_project_address_by_projectIdService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseIdService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_header_by_shippingContainerIdService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_lines_archived_contentsService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_lines_contentsService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_lines_mergedService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_packing_slip_report_order_lookupService } from './Reports.datasource.index';
import { Reports_custom_ds_packing_list_report_headerService } from './Reports.datasource.index';
import { Reports_custom_ds_receiving_details_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_receiving_header_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_vector_labelService } from './Reports.datasource.index';
import { Reports_ds_Outbound_LabelsService } from './Reports.datasource.index';
import { Reports_ds_get_inbound_totals_by_linenumber_orderIdService } from './Reports.datasource.index';
import { Reports_ds_get_order_account_address_by_orderIdService } from './Reports.datasource.index';
import { Reports_ds_get_order_project_contact_top1Service } from './Reports.datasource.index';
import { Reports_ds_get_order_warehouse_contact_top1Service } from './Reports.datasource.index';

@Injectable({ providedIn: 'root' })
export class Reports_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_custom_receiving_report_dataLoggers: Reports_custom_ds_custom_receiving_report_dataLoggersService;
  public get custom_ds_custom_receiving_report_dataLoggers(): Reports_custom_ds_custom_receiving_report_dataLoggersService {
    if(!this._custom_ds_custom_receiving_report_dataLoggers) {
      this._custom_ds_custom_receiving_report_dataLoggers = this.injector.get(Reports_custom_ds_custom_receiving_report_dataLoggersService);
    }
    return this._custom_ds_custom_receiving_report_dataLoggers;
  }
  private _custom_ds_cycle_count_report_details: Reports_custom_ds_cycle_count_report_detailsService;
  public get custom_ds_cycle_count_report_details(): Reports_custom_ds_cycle_count_report_detailsService {
    if(!this._custom_ds_cycle_count_report_details) {
      this._custom_ds_cycle_count_report_details = this.injector.get(Reports_custom_ds_cycle_count_report_detailsService);
    }
    return this._custom_ds_cycle_count_report_details;
  }
  private _custom_ds_cycle_count_report_recount_details: Reports_custom_ds_cycle_count_report_recount_detailsService;
  public get custom_ds_cycle_count_report_recount_details(): Reports_custom_ds_cycle_count_report_recount_detailsService {
    if(!this._custom_ds_cycle_count_report_recount_details) {
      this._custom_ds_cycle_count_report_recount_details = this.injector.get(Reports_custom_ds_cycle_count_report_recount_detailsService);
    }
    return this._custom_ds_cycle_count_report_recount_details;
  }
  private _custom_ds_dock_appointment_details_report: Reports_custom_ds_dock_appointment_details_reportService;
  public get custom_ds_dock_appointment_details_report(): Reports_custom_ds_dock_appointment_details_reportService {
    if(!this._custom_ds_dock_appointment_details_report) {
      this._custom_ds_dock_appointment_details_report = this.injector.get(Reports_custom_ds_dock_appointment_details_reportService);
    }
    return this._custom_ds_dock_appointment_details_report;
  }
  private _custom_ds_get_deliver_to_country_by_shipmentId: Reports_custom_ds_get_deliver_to_country_by_shipmentIdService;
  public get custom_ds_get_deliver_to_country_by_shipmentId(): Reports_custom_ds_get_deliver_to_country_by_shipmentIdService {
    if(!this._custom_ds_get_deliver_to_country_by_shipmentId) {
      this._custom_ds_get_deliver_to_country_by_shipmentId = this.injector.get(Reports_custom_ds_get_deliver_to_country_by_shipmentIdService);
    }
    return this._custom_ds_get_deliver_to_country_by_shipmentId;
  }
  private _custom_ds_get_dock_appointment_by_orderId: Reports_custom_ds_get_dock_appointment_by_orderIdService;
  public get custom_ds_get_dock_appointment_by_orderId(): Reports_custom_ds_get_dock_appointment_by_orderIdService {
    if(!this._custom_ds_get_dock_appointment_by_orderId) {
      this._custom_ds_get_dock_appointment_by_orderId = this.injector.get(Reports_custom_ds_get_dock_appointment_by_orderIdService);
    }
    return this._custom_ds_get_dock_appointment_by_orderId;
  }
  private _custom_ds_get_order_account_address_by_orderId: Reports_custom_ds_get_order_account_address_by_orderIdService;
  public get custom_ds_get_order_account_address_by_orderId(): Reports_custom_ds_get_order_account_address_by_orderIdService {
    if(!this._custom_ds_get_order_account_address_by_orderId) {
      this._custom_ds_get_order_account_address_by_orderId = this.injector.get(Reports_custom_ds_get_order_account_address_by_orderIdService);
    }
    return this._custom_ds_get_order_account_address_by_orderId;
  }
  private _custom_ds_get_order_address_by_orderId_top1: Reports_custom_ds_get_order_address_by_orderId_top1Service;
  public get custom_ds_get_order_address_by_orderId_top1(): Reports_custom_ds_get_order_address_by_orderId_top1Service {
    if(!this._custom_ds_get_order_address_by_orderId_top1) {
      this._custom_ds_get_order_address_by_orderId_top1 = this.injector.get(Reports_custom_ds_get_order_address_by_orderId_top1Service);
    }
    return this._custom_ds_get_order_address_by_orderId_top1;
  }
  private _custom_ds_get_order_ship_to_account_vs_order_address: Reports_custom_ds_get_order_ship_to_account_vs_order_addressService;
  public get custom_ds_get_order_ship_to_account_vs_order_address(): Reports_custom_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._custom_ds_get_order_ship_to_account_vs_order_address) {
      this._custom_ds_get_order_ship_to_account_vs_order_address = this.injector.get(Reports_custom_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._custom_ds_get_order_ship_to_account_vs_order_address;
  }
  private _custom_ds_get_order_sscc_label_type: Reports_custom_ds_get_order_sscc_label_typeService;
  public get custom_ds_get_order_sscc_label_type(): Reports_custom_ds_get_order_sscc_label_typeService {
    if(!this._custom_ds_get_order_sscc_label_type) {
      this._custom_ds_get_order_sscc_label_type = this.injector.get(Reports_custom_ds_get_order_sscc_label_typeService);
    }
    return this._custom_ds_get_order_sscc_label_type;
  }
  private _custom_ds_get_price_orderline_material: Reports_custom_ds_get_price_orderline_materialService;
  public get custom_ds_get_price_orderline_material(): Reports_custom_ds_get_price_orderline_materialService {
    if(!this._custom_ds_get_price_orderline_material) {
      this._custom_ds_get_price_orderline_material = this.injector.get(Reports_custom_ds_get_price_orderline_materialService);
    }
    return this._custom_ds_get_price_orderline_material;
  }
  private _custom_ds_get_serial_numbers_by_pallet_id: Reports_custom_ds_get_serial_numbers_by_pallet_idService;
  public get custom_ds_get_serial_numbers_by_pallet_id(): Reports_custom_ds_get_serial_numbers_by_pallet_idService {
    if(!this._custom_ds_get_serial_numbers_by_pallet_id) {
      this._custom_ds_get_serial_numbers_by_pallet_id = this.injector.get(Reports_custom_ds_get_serial_numbers_by_pallet_idService);
    }
    return this._custom_ds_get_serial_numbers_by_pallet_id;
  }
  private _custom_ds_get_shipping_containers_tasks: Reports_custom_ds_get_shipping_containers_tasksService;
  public get custom_ds_get_shipping_containers_tasks(): Reports_custom_ds_get_shipping_containers_tasksService {
    if(!this._custom_ds_get_shipping_containers_tasks) {
      this._custom_ds_get_shipping_containers_tasks = this.injector.get(Reports_custom_ds_get_shipping_containers_tasksService);
    }
    return this._custom_ds_get_shipping_containers_tasks;
  }
  private _custom_ds_get_survey_answer_driver_photo_by_appointmentId: Reports_custom_ds_get_survey_answer_driver_photo_by_appointmentIdService;
  public get custom_ds_get_survey_answer_driver_photo_by_appointmentId(): Reports_custom_ds_get_survey_answer_driver_photo_by_appointmentIdService {
    if(!this._custom_ds_get_survey_answer_driver_photo_by_appointmentId) {
      this._custom_ds_get_survey_answer_driver_photo_by_appointmentId = this.injector.get(Reports_custom_ds_get_survey_answer_driver_photo_by_appointmentIdService);
    }
    return this._custom_ds_get_survey_answer_driver_photo_by_appointmentId;
  }
  private _custom_ds_get_survey_answer_first_name_by_appointmentId: Reports_custom_ds_get_survey_answer_first_name_by_appointmentIdService;
  public get custom_ds_get_survey_answer_first_name_by_appointmentId(): Reports_custom_ds_get_survey_answer_first_name_by_appointmentIdService {
    if(!this._custom_ds_get_survey_answer_first_name_by_appointmentId) {
      this._custom_ds_get_survey_answer_first_name_by_appointmentId = this.injector.get(Reports_custom_ds_get_survey_answer_first_name_by_appointmentIdService);
    }
    return this._custom_ds_get_survey_answer_first_name_by_appointmentId;
  }
  private _custom_ds_get_survey_answer_seal_number_by_orderId: Reports_custom_ds_get_survey_answer_seal_number_by_orderIdService;
  public get custom_ds_get_survey_answer_seal_number_by_orderId(): Reports_custom_ds_get_survey_answer_seal_number_by_orderIdService {
    if(!this._custom_ds_get_survey_answer_seal_number_by_orderId) {
      this._custom_ds_get_survey_answer_seal_number_by_orderId = this.injector.get(Reports_custom_ds_get_survey_answer_seal_number_by_orderIdService);
    }
    return this._custom_ds_get_survey_answer_seal_number_by_orderId;
  }
  private _custom_ds_get_survey_answer_trailer_number_by_orderId: Reports_custom_ds_get_survey_answer_trailer_number_by_orderIdService;
  public get custom_ds_get_survey_answer_trailer_number_by_orderId(): Reports_custom_ds_get_survey_answer_trailer_number_by_orderIdService {
    if(!this._custom_ds_get_survey_answer_trailer_number_by_orderId) {
      this._custom_ds_get_survey_answer_trailer_number_by_orderId = this.injector.get(Reports_custom_ds_get_survey_answer_trailer_number_by_orderIdService);
    }
    return this._custom_ds_get_survey_answer_trailer_number_by_orderId;
  }
  private _custom_ds_licenseplate_details_by_serialId: Reports_custom_ds_licenseplate_details_by_serialIdService;
  public get custom_ds_licenseplate_details_by_serialId(): Reports_custom_ds_licenseplate_details_by_serialIdService {
    if(!this._custom_ds_licenseplate_details_by_serialId) {
      this._custom_ds_licenseplate_details_by_serialId = this.injector.get(Reports_custom_ds_licenseplate_details_by_serialIdService);
    }
    return this._custom_ds_licenseplate_details_by_serialId;
  }
  private _custom_ds_outbound_label_by_shippingContainerId: Reports_custom_ds_outbound_label_by_shippingContainerIdService;
  public get custom_ds_outbound_label_by_shippingContainerId(): Reports_custom_ds_outbound_label_by_shippingContainerIdService {
    if(!this._custom_ds_outbound_label_by_shippingContainerId) {
      this._custom_ds_outbound_label_by_shippingContainerId = this.injector.get(Reports_custom_ds_outbound_label_by_shippingContainerIdService);
    }
    return this._custom_ds_outbound_label_by_shippingContainerId;
  }
  private _custom_ds_outbound_packing_slip_get_order_address_by_orderId_name: Reports_custom_ds_outbound_packing_slip_get_order_address_by_orderId_nameService;
  public get custom_ds_outbound_packing_slip_get_order_address_by_orderId_name(): Reports_custom_ds_outbound_packing_slip_get_order_address_by_orderId_nameService {
    if(!this._custom_ds_outbound_packing_slip_get_order_address_by_orderId_name) {
      this._custom_ds_outbound_packing_slip_get_order_address_by_orderId_name = this.injector.get(Reports_custom_ds_outbound_packing_slip_get_order_address_by_orderId_nameService);
    }
    return this._custom_ds_outbound_packing_slip_get_order_address_by_orderId_name;
  }
  private _custom_ds_outbound_packing_slip_get_project_address_by_projectId: Reports_custom_ds_outbound_packing_slip_get_project_address_by_projectIdService;
  public get custom_ds_outbound_packing_slip_get_project_address_by_projectId(): Reports_custom_ds_outbound_packing_slip_get_project_address_by_projectIdService {
    if(!this._custom_ds_outbound_packing_slip_get_project_address_by_projectId) {
      this._custom_ds_outbound_packing_slip_get_project_address_by_projectId = this.injector.get(Reports_custom_ds_outbound_packing_slip_get_project_address_by_projectIdService);
    }
    return this._custom_ds_outbound_packing_slip_get_project_address_by_projectId;
  }
  private _custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId: Reports_custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseIdService;
  public get custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId(): Reports_custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseIdService {
    if(!this._custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId) {
      this._custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId = this.injector.get(Reports_custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseIdService);
    }
    return this._custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId;
  }
  private _custom_ds_outbound_packing_slip_header_by_shippingContainerId: Reports_custom_ds_outbound_packing_slip_header_by_shippingContainerIdService;
  public get custom_ds_outbound_packing_slip_header_by_shippingContainerId(): Reports_custom_ds_outbound_packing_slip_header_by_shippingContainerIdService {
    if(!this._custom_ds_outbound_packing_slip_header_by_shippingContainerId) {
      this._custom_ds_outbound_packing_slip_header_by_shippingContainerId = this.injector.get(Reports_custom_ds_outbound_packing_slip_header_by_shippingContainerIdService);
    }
    return this._custom_ds_outbound_packing_slip_header_by_shippingContainerId;
  }
  private _custom_ds_outbound_packing_slip_lines_archived_contents: Reports_custom_ds_outbound_packing_slip_lines_archived_contentsService;
  public get custom_ds_outbound_packing_slip_lines_archived_contents(): Reports_custom_ds_outbound_packing_slip_lines_archived_contentsService {
    if(!this._custom_ds_outbound_packing_slip_lines_archived_contents) {
      this._custom_ds_outbound_packing_slip_lines_archived_contents = this.injector.get(Reports_custom_ds_outbound_packing_slip_lines_archived_contentsService);
    }
    return this._custom_ds_outbound_packing_slip_lines_archived_contents;
  }
  private _custom_ds_outbound_packing_slip_lines_contents: Reports_custom_ds_outbound_packing_slip_lines_contentsService;
  public get custom_ds_outbound_packing_slip_lines_contents(): Reports_custom_ds_outbound_packing_slip_lines_contentsService {
    if(!this._custom_ds_outbound_packing_slip_lines_contents) {
      this._custom_ds_outbound_packing_slip_lines_contents = this.injector.get(Reports_custom_ds_outbound_packing_slip_lines_contentsService);
    }
    return this._custom_ds_outbound_packing_slip_lines_contents;
  }
  private _custom_ds_outbound_packing_slip_lines_merged: Reports_custom_ds_outbound_packing_slip_lines_mergedService;
  public get custom_ds_outbound_packing_slip_lines_merged(): Reports_custom_ds_outbound_packing_slip_lines_mergedService {
    if(!this._custom_ds_outbound_packing_slip_lines_merged) {
      this._custom_ds_outbound_packing_slip_lines_merged = this.injector.get(Reports_custom_ds_outbound_packing_slip_lines_mergedService);
    }
    return this._custom_ds_outbound_packing_slip_lines_merged;
  }
  private _custom_ds_outbound_packing_slip_report_order_lookup: Reports_custom_ds_outbound_packing_slip_report_order_lookupService;
  public get custom_ds_outbound_packing_slip_report_order_lookup(): Reports_custom_ds_outbound_packing_slip_report_order_lookupService {
    if(!this._custom_ds_outbound_packing_slip_report_order_lookup) {
      this._custom_ds_outbound_packing_slip_report_order_lookup = this.injector.get(Reports_custom_ds_outbound_packing_slip_report_order_lookupService);
    }
    return this._custom_ds_outbound_packing_slip_report_order_lookup;
  }
  private _custom_ds_packing_list_report_header: Reports_custom_ds_packing_list_report_headerService;
  public get custom_ds_packing_list_report_header(): Reports_custom_ds_packing_list_report_headerService {
    if(!this._custom_ds_packing_list_report_header) {
      this._custom_ds_packing_list_report_header = this.injector.get(Reports_custom_ds_packing_list_report_headerService);
    }
    return this._custom_ds_packing_list_report_header;
  }
  private _custom_ds_receiving_details_report: Reports_custom_ds_receiving_details_reportService;
  public get custom_ds_receiving_details_report(): Reports_custom_ds_receiving_details_reportService {
    if(!this._custom_ds_receiving_details_report) {
      this._custom_ds_receiving_details_report = this.injector.get(Reports_custom_ds_receiving_details_reportService);
    }
    return this._custom_ds_receiving_details_report;
  }
  private _custom_ds_receiving_header_report: Reports_custom_ds_receiving_header_reportService;
  public get custom_ds_receiving_header_report(): Reports_custom_ds_receiving_header_reportService {
    if(!this._custom_ds_receiving_header_report) {
      this._custom_ds_receiving_header_report = this.injector.get(Reports_custom_ds_receiving_header_reportService);
    }
    return this._custom_ds_receiving_header_report;
  }
  private _custom_ds_vector_label: Reports_custom_ds_vector_labelService;
  public get custom_ds_vector_label(): Reports_custom_ds_vector_labelService {
    if(!this._custom_ds_vector_label) {
      this._custom_ds_vector_label = this.injector.get(Reports_custom_ds_vector_labelService);
    }
    return this._custom_ds_vector_label;
  }
  private _ds_Outbound_Labels: Reports_ds_Outbound_LabelsService;
  public get ds_Outbound_Labels(): Reports_ds_Outbound_LabelsService {
    if(!this._ds_Outbound_Labels) {
      this._ds_Outbound_Labels = this.injector.get(Reports_ds_Outbound_LabelsService);
    }
    return this._ds_Outbound_Labels;
  }
  private _ds_get_inbound_totals_by_linenumber_orderId: Reports_ds_get_inbound_totals_by_linenumber_orderIdService;
  public get ds_get_inbound_totals_by_linenumber_orderId(): Reports_ds_get_inbound_totals_by_linenumber_orderIdService {
    if(!this._ds_get_inbound_totals_by_linenumber_orderId) {
      this._ds_get_inbound_totals_by_linenumber_orderId = this.injector.get(Reports_ds_get_inbound_totals_by_linenumber_orderIdService);
    }
    return this._ds_get_inbound_totals_by_linenumber_orderId;
  }
  private _ds_get_order_account_address_by_orderId: Reports_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): Reports_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(Reports_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_project_contact_top1: Reports_ds_get_order_project_contact_top1Service;
  public get ds_get_order_project_contact_top1(): Reports_ds_get_order_project_contact_top1Service {
    if(!this._ds_get_order_project_contact_top1) {
      this._ds_get_order_project_contact_top1 = this.injector.get(Reports_ds_get_order_project_contact_top1Service);
    }
    return this._ds_get_order_project_contact_top1;
  }
  private _ds_get_order_warehouse_contact_top1: Reports_ds_get_order_warehouse_contact_top1Service;
  public get ds_get_order_warehouse_contact_top1(): Reports_ds_get_order_warehouse_contact_top1Service {
    if(!this._ds_get_order_warehouse_contact_top1) {
      this._ds_get_order_warehouse_contact_top1 = this.injector.get(Reports_ds_get_order_warehouse_contact_top1Service);
    }
    return this._ds_get_order_warehouse_contact_top1;
  }
}

