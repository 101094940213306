import { Inject, Injectable, Injector }from '@angular/core';


import { SalesOrders_ds_container_sizes_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_countries_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_find_tasksService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_freight_terms_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_licenseplateId_lotIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_lotId_locationIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_lotId_packagedId_locationIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_lotId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_available_inventory_by_materialId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_completed_picking_tasks_by_orderId_shipmentId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_completed_tasks_by_orderId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_containercodes_by_shippingContainerIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_current_bol_seedService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_default_processing_strategyService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_full_licenseplate_pick_task_child_tasksService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_full_licenseplate_picking_tasks_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_hardallocationdetails_by_hardallocationIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_hardallocations_by_taskIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplate_by_licenseplateIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplate_by_loose_locationIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplatecontents_by_licenseplateIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplates_by_locationId_lotId_packagingIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_licenseplates_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_loading_tasks_by_licenseplateId_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_location_by_locationIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_location_by_lotId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_lot_by_lotIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_lot_serial_weight_avgService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_material_by_materialIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_material_kit_order_line_by_order_id_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_material_kit_order_lines_by_order_idService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_material_packaging_by_materialIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_material_packaging_by_materialId_and_packagingIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_fixed_weight_details_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_non_fixed_weight_details_by_shipmentId_completedService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_account_address_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_address_by_orderId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_aggregated_tasksService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_by_licenseplateService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_by_lookupcodeService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_by_orderId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_lines_by_order_id_line_numberService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_lines_with_serial_numbersService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_owner_contact_by_typeIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_owner_contact_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_ship_account_vs_order_addressService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_ship_to_account_vs_order_addressService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_signature_attachmentService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_total_picked_units_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_total_picked_units_by_orderIdsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_total_picked_units_by_variousService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_total_picked_vs_expected_units_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_total_units_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_total_units_by_orderIdsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_order_warehouse_contact_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderaddresses_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderline_by_order_id_line_numberService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderline_total_picked_units_by_orderId_linenumberService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderlines_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderlines_by_orderId_bottom1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderlines_by_orderId_linestatusidsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderlines_by_orderId_without_childrenService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orderlines_missing_shipment_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orders_aggregated_tasksService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_orders_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_outbound_processing_strategies_by_projectIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_picking_tasks_by_orderId_orderLineNumbersService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_picking_tasks_by_orderId_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_pickslips_by_waveIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_released_manual_allocations_by_orderId_shipmentId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_released_pick_tasks_by_chainheadService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_released_picking_tasks_by_orderId_shipmentId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumber_by_serialnumberIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentLines_by_orderId_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentLines_by_orderId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentLines_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentLines_by_shipmentId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipment_aggregated_licenseplatesService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipment_aggregated_tasksService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipment_by_orderId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentline_by_shipmentlineIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentline_by_shipmentlineId_detailedService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentlines_by_orderId_bottom1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipments_aggregated_licenseplatesService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipments_aggregated_tasksService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipments_by_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipments_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipments_from_ordersService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shipmentscontents_by_orderId_shipmentId_top1Service } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shippingcontainers_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shippingcontents_by_licenseplateIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shippingcontents_by_shipmentId_orderIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_task_by_taskIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_tasks_by_licenseplateIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_tasks_by_orderId_orderLineNumbersService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_tasks_by_orderId_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_tasks_by_shipmentIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_warehouse_by_warehouseIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_warehouses_by_warehouseIdsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_get_wave_by_waveIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_license_plate_labels_by_order_reportService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_licenseplates_by_locationId_lotId_packagingId_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_load_slip_order_lines_reportService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_locations_eligible_for_allocation_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_manual_allocations_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_loaded_percentage_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_picked_percentage_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_completed_order_count_by_dateService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_completed_order_count_by_date_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_pallet_count_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_gross_by_dateService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_gross_by_date_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_gross_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_order_count_by_dateService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_order_count_by_date_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_unitsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_units_by_dateService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_units_by_date_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_units_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_weight_by_orderId_lotId_packagedIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_picked_weight_by_orderId_materialIdService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_order_total_units_widgetService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_orderclasses_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_orderstatuses_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_outbound_details_bill_of_lading_reportService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_outbound_details_bill_of_lading_report_advancedService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_outbound_header_bill_of_lading_reportService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_outbound_orders_details_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_outbound_orders_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_outbound_pick_slip_by_orderId_reportService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_packing_list_report_detailsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_packing_list_report_headerService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_processing_strategy_workflows_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_sales_order_editorService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_sales_order_lines_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_sales_order_picking_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_sales_orders_children_tasks_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_sales_orders_tasks_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_shipment_archived_contentsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_shipment_contentsService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_shipment_expectedService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_shipment_priorities_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_shipment_processedService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_shippingcontents_gridService } from './SalesOrders.datasource.index';
import { SalesOrders_ds_wave_priorities_ddService } from './SalesOrders.datasource.index';
import { SalesOrders_testing_flow_based_data_source_for_gridsService } from './SalesOrders.datasource.index';

@Injectable({ providedIn: 'root' })
export class SalesOrders_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public SalesOrders: SalesOrders_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_container_sizes_dd: SalesOrders_ds_container_sizes_ddService;
  public get ds_container_sizes_dd(): SalesOrders_ds_container_sizes_ddService {
    if(!this._ds_container_sizes_dd) {
      this._ds_container_sizes_dd = this.injector.get(SalesOrders_ds_container_sizes_ddService);
    }
    return this._ds_container_sizes_dd;
  }
  private _ds_countries_dd: SalesOrders_ds_countries_ddService;
  public get ds_countries_dd(): SalesOrders_ds_countries_ddService {
    if(!this._ds_countries_dd) {
      this._ds_countries_dd = this.injector.get(SalesOrders_ds_countries_ddService);
    }
    return this._ds_countries_dd;
  }
  private _ds_find_tasks: SalesOrders_ds_find_tasksService;
  public get ds_find_tasks(): SalesOrders_ds_find_tasksService {
    if(!this._ds_find_tasks) {
      this._ds_find_tasks = this.injector.get(SalesOrders_ds_find_tasksService);
    }
    return this._ds_find_tasks;
  }
  private _ds_freight_terms_dd: SalesOrders_ds_freight_terms_ddService;
  public get ds_freight_terms_dd(): SalesOrders_ds_freight_terms_ddService {
    if(!this._ds_freight_terms_dd) {
      this._ds_freight_terms_dd = this.injector.get(SalesOrders_ds_freight_terms_ddService);
    }
    return this._ds_freight_terms_dd;
  }
  private _ds_get_available_inventory_by_licenseplateId_lotId: SalesOrders_ds_get_available_inventory_by_licenseplateId_lotIdService;
  public get ds_get_available_inventory_by_licenseplateId_lotId(): SalesOrders_ds_get_available_inventory_by_licenseplateId_lotIdService {
    if(!this._ds_get_available_inventory_by_licenseplateId_lotId) {
      this._ds_get_available_inventory_by_licenseplateId_lotId = this.injector.get(SalesOrders_ds_get_available_inventory_by_licenseplateId_lotIdService);
    }
    return this._ds_get_available_inventory_by_licenseplateId_lotId;
  }
  private _ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId: SalesOrders_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId(): SalesOrders_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId = this.injector.get(SalesOrders_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId;
  }
  private _ds_get_available_inventory_by_lotId_locationId: SalesOrders_ds_get_available_inventory_by_lotId_locationIdService;
  public get ds_get_available_inventory_by_lotId_locationId(): SalesOrders_ds_get_available_inventory_by_lotId_locationIdService {
    if(!this._ds_get_available_inventory_by_lotId_locationId) {
      this._ds_get_available_inventory_by_lotId_locationId = this.injector.get(SalesOrders_ds_get_available_inventory_by_lotId_locationIdService);
    }
    return this._ds_get_available_inventory_by_lotId_locationId;
  }
  private _ds_get_available_inventory_by_lotId_packagedId_locationId: SalesOrders_ds_get_available_inventory_by_lotId_packagedId_locationIdService;
  public get ds_get_available_inventory_by_lotId_packagedId_locationId(): SalesOrders_ds_get_available_inventory_by_lotId_packagedId_locationIdService {
    if(!this._ds_get_available_inventory_by_lotId_packagedId_locationId) {
      this._ds_get_available_inventory_by_lotId_packagedId_locationId = this.injector.get(SalesOrders_ds_get_available_inventory_by_lotId_packagedId_locationIdService);
    }
    return this._ds_get_available_inventory_by_lotId_packagedId_locationId;
  }
  private _ds_get_available_inventory_by_lotId_packagedId_warehouseId: SalesOrders_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_lotId_packagedId_warehouseId(): SalesOrders_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_lotId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_lotId_packagedId_warehouseId = this.injector.get(SalesOrders_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_lotId_packagedId_warehouseId;
  }
  private _ds_get_available_inventory_by_lotId_warehouseId: SalesOrders_ds_get_available_inventory_by_lotId_warehouseIdService;
  public get ds_get_available_inventory_by_lotId_warehouseId(): SalesOrders_ds_get_available_inventory_by_lotId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_lotId_warehouseId) {
      this._ds_get_available_inventory_by_lotId_warehouseId = this.injector.get(SalesOrders_ds_get_available_inventory_by_lotId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_lotId_warehouseId;
  }
  private _ds_get_available_inventory_by_materialId_packagedId_warehouseId: SalesOrders_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_materialId_packagedId_warehouseId(): SalesOrders_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_materialId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_materialId_packagedId_warehouseId = this.injector.get(SalesOrders_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_materialId_packagedId_warehouseId;
  }
  private _ds_get_available_inventory_by_materialId_warehouseId: SalesOrders_ds_get_available_inventory_by_materialId_warehouseIdService;
  public get ds_get_available_inventory_by_materialId_warehouseId(): SalesOrders_ds_get_available_inventory_by_materialId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_materialId_warehouseId) {
      this._ds_get_available_inventory_by_materialId_warehouseId = this.injector.get(SalesOrders_ds_get_available_inventory_by_materialId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_materialId_warehouseId;
  }
  private _ds_get_completed_picking_tasks_by_orderId_shipmentId_top1: SalesOrders_ds_get_completed_picking_tasks_by_orderId_shipmentId_top1Service;
  public get ds_get_completed_picking_tasks_by_orderId_shipmentId_top1(): SalesOrders_ds_get_completed_picking_tasks_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_completed_picking_tasks_by_orderId_shipmentId_top1) {
      this._ds_get_completed_picking_tasks_by_orderId_shipmentId_top1 = this.injector.get(SalesOrders_ds_get_completed_picking_tasks_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_completed_picking_tasks_by_orderId_shipmentId_top1;
  }
  private _ds_get_completed_tasks_by_orderId_top1: SalesOrders_ds_get_completed_tasks_by_orderId_top1Service;
  public get ds_get_completed_tasks_by_orderId_top1(): SalesOrders_ds_get_completed_tasks_by_orderId_top1Service {
    if(!this._ds_get_completed_tasks_by_orderId_top1) {
      this._ds_get_completed_tasks_by_orderId_top1 = this.injector.get(SalesOrders_ds_get_completed_tasks_by_orderId_top1Service);
    }
    return this._ds_get_completed_tasks_by_orderId_top1;
  }
  private _ds_get_containercodes_by_shippingContainerId: SalesOrders_ds_get_containercodes_by_shippingContainerIdService;
  public get ds_get_containercodes_by_shippingContainerId(): SalesOrders_ds_get_containercodes_by_shippingContainerIdService {
    if(!this._ds_get_containercodes_by_shippingContainerId) {
      this._ds_get_containercodes_by_shippingContainerId = this.injector.get(SalesOrders_ds_get_containercodes_by_shippingContainerIdService);
    }
    return this._ds_get_containercodes_by_shippingContainerId;
  }
  private _ds_get_current_bol_seed: SalesOrders_ds_get_current_bol_seedService;
  public get ds_get_current_bol_seed(): SalesOrders_ds_get_current_bol_seedService {
    if(!this._ds_get_current_bol_seed) {
      this._ds_get_current_bol_seed = this.injector.get(SalesOrders_ds_get_current_bol_seedService);
    }
    return this._ds_get_current_bol_seed;
  }
  private _ds_get_default_processing_strategy: SalesOrders_ds_get_default_processing_strategyService;
  public get ds_get_default_processing_strategy(): SalesOrders_ds_get_default_processing_strategyService {
    if(!this._ds_get_default_processing_strategy) {
      this._ds_get_default_processing_strategy = this.injector.get(SalesOrders_ds_get_default_processing_strategyService);
    }
    return this._ds_get_default_processing_strategy;
  }
  private _ds_get_full_licenseplate_pick_task_child_tasks: SalesOrders_ds_get_full_licenseplate_pick_task_child_tasksService;
  public get ds_get_full_licenseplate_pick_task_child_tasks(): SalesOrders_ds_get_full_licenseplate_pick_task_child_tasksService {
    if(!this._ds_get_full_licenseplate_pick_task_child_tasks) {
      this._ds_get_full_licenseplate_pick_task_child_tasks = this.injector.get(SalesOrders_ds_get_full_licenseplate_pick_task_child_tasksService);
    }
    return this._ds_get_full_licenseplate_pick_task_child_tasks;
  }
  private _ds_get_full_licenseplate_picking_tasks_by_shipmentId: SalesOrders_ds_get_full_licenseplate_picking_tasks_by_shipmentIdService;
  public get ds_get_full_licenseplate_picking_tasks_by_shipmentId(): SalesOrders_ds_get_full_licenseplate_picking_tasks_by_shipmentIdService {
    if(!this._ds_get_full_licenseplate_picking_tasks_by_shipmentId) {
      this._ds_get_full_licenseplate_picking_tasks_by_shipmentId = this.injector.get(SalesOrders_ds_get_full_licenseplate_picking_tasks_by_shipmentIdService);
    }
    return this._ds_get_full_licenseplate_picking_tasks_by_shipmentId;
  }
  private _ds_get_hardallocationdetails_by_hardallocationId: SalesOrders_ds_get_hardallocationdetails_by_hardallocationIdService;
  public get ds_get_hardallocationdetails_by_hardallocationId(): SalesOrders_ds_get_hardallocationdetails_by_hardallocationIdService {
    if(!this._ds_get_hardallocationdetails_by_hardallocationId) {
      this._ds_get_hardallocationdetails_by_hardallocationId = this.injector.get(SalesOrders_ds_get_hardallocationdetails_by_hardallocationIdService);
    }
    return this._ds_get_hardallocationdetails_by_hardallocationId;
  }
  private _ds_get_hardallocations_by_taskId: SalesOrders_ds_get_hardallocations_by_taskIdService;
  public get ds_get_hardallocations_by_taskId(): SalesOrders_ds_get_hardallocations_by_taskIdService {
    if(!this._ds_get_hardallocations_by_taskId) {
      this._ds_get_hardallocations_by_taskId = this.injector.get(SalesOrders_ds_get_hardallocations_by_taskIdService);
    }
    return this._ds_get_hardallocations_by_taskId;
  }
  private _ds_get_licenseplate_by_licenseplateId: SalesOrders_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): SalesOrders_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(SalesOrders_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId: SalesOrders_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService;
  public get ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId(): SalesOrders_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService {
    if(!this._ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId) {
      this._ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId = this.injector.get(SalesOrders_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService);
    }
    return this._ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId;
  }
  private _ds_get_licenseplate_by_loose_locationId: SalesOrders_ds_get_licenseplate_by_loose_locationIdService;
  public get ds_get_licenseplate_by_loose_locationId(): SalesOrders_ds_get_licenseplate_by_loose_locationIdService {
    if(!this._ds_get_licenseplate_by_loose_locationId) {
      this._ds_get_licenseplate_by_loose_locationId = this.injector.get(SalesOrders_ds_get_licenseplate_by_loose_locationIdService);
    }
    return this._ds_get_licenseplate_by_loose_locationId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId: SalesOrders_ds_get_licenseplatecontents_by_licenseplateIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId(): SalesOrders_ds_get_licenseplatecontents_by_licenseplateIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId) {
      this._ds_get_licenseplatecontents_by_licenseplateId = this.injector.get(SalesOrders_ds_get_licenseplatecontents_by_licenseplateIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId;
  }
  private _ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId: SalesOrders_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService;
  public get ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId(): SalesOrders_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService {
    if(!this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId) {
      this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId = this.injector.get(SalesOrders_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService);
    }
    return this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId;
  }
  private _ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId: SalesOrders_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService;
  public get ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId(): SalesOrders_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService {
    if(!this._ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId) {
      this._ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId = this.injector.get(SalesOrders_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService);
    }
    return this._ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId;
  }
  private _ds_get_licenseplates_by_locationId_lotId_packagingId: SalesOrders_ds_get_licenseplates_by_locationId_lotId_packagingIdService;
  public get ds_get_licenseplates_by_locationId_lotId_packagingId(): SalesOrders_ds_get_licenseplates_by_locationId_lotId_packagingIdService {
    if(!this._ds_get_licenseplates_by_locationId_lotId_packagingId) {
      this._ds_get_licenseplates_by_locationId_lotId_packagingId = this.injector.get(SalesOrders_ds_get_licenseplates_by_locationId_lotId_packagingIdService);
    }
    return this._ds_get_licenseplates_by_locationId_lotId_packagingId;
  }
  private _ds_get_licenseplates_by_lookupcode_and_warehouseId: SalesOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService;
  public get ds_get_licenseplates_by_lookupcode_and_warehouseId(): SalesOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService {
    if(!this._ds_get_licenseplates_by_lookupcode_and_warehouseId) {
      this._ds_get_licenseplates_by_lookupcode_and_warehouseId = this.injector.get(SalesOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService);
    }
    return this._ds_get_licenseplates_by_lookupcode_and_warehouseId;
  }
  private _ds_get_licenseplates_by_shipmentId: SalesOrders_ds_get_licenseplates_by_shipmentIdService;
  public get ds_get_licenseplates_by_shipmentId(): SalesOrders_ds_get_licenseplates_by_shipmentIdService {
    if(!this._ds_get_licenseplates_by_shipmentId) {
      this._ds_get_licenseplates_by_shipmentId = this.injector.get(SalesOrders_ds_get_licenseplates_by_shipmentIdService);
    }
    return this._ds_get_licenseplates_by_shipmentId;
  }
  private _ds_get_loading_tasks_by_licenseplateId_shipmentId: SalesOrders_ds_get_loading_tasks_by_licenseplateId_shipmentIdService;
  public get ds_get_loading_tasks_by_licenseplateId_shipmentId(): SalesOrders_ds_get_loading_tasks_by_licenseplateId_shipmentIdService {
    if(!this._ds_get_loading_tasks_by_licenseplateId_shipmentId) {
      this._ds_get_loading_tasks_by_licenseplateId_shipmentId = this.injector.get(SalesOrders_ds_get_loading_tasks_by_licenseplateId_shipmentIdService);
    }
    return this._ds_get_loading_tasks_by_licenseplateId_shipmentId;
  }
  private _ds_get_location_by_locationId: SalesOrders_ds_get_location_by_locationIdService;
  public get ds_get_location_by_locationId(): SalesOrders_ds_get_location_by_locationIdService {
    if(!this._ds_get_location_by_locationId) {
      this._ds_get_location_by_locationId = this.injector.get(SalesOrders_ds_get_location_by_locationIdService);
    }
    return this._ds_get_location_by_locationId;
  }
  private _ds_get_location_by_lotId_top1: SalesOrders_ds_get_location_by_lotId_top1Service;
  public get ds_get_location_by_lotId_top1(): SalesOrders_ds_get_location_by_lotId_top1Service {
    if(!this._ds_get_location_by_lotId_top1) {
      this._ds_get_location_by_lotId_top1 = this.injector.get(SalesOrders_ds_get_location_by_lotId_top1Service);
    }
    return this._ds_get_location_by_lotId_top1;
  }
  private _ds_get_lot_by_lotId: SalesOrders_ds_get_lot_by_lotIdService;
  public get ds_get_lot_by_lotId(): SalesOrders_ds_get_lot_by_lotIdService {
    if(!this._ds_get_lot_by_lotId) {
      this._ds_get_lot_by_lotId = this.injector.get(SalesOrders_ds_get_lot_by_lotIdService);
    }
    return this._ds_get_lot_by_lotId;
  }
  private _ds_get_lot_serial_weight_avg: SalesOrders_ds_get_lot_serial_weight_avgService;
  public get ds_get_lot_serial_weight_avg(): SalesOrders_ds_get_lot_serial_weight_avgService {
    if(!this._ds_get_lot_serial_weight_avg) {
      this._ds_get_lot_serial_weight_avg = this.injector.get(SalesOrders_ds_get_lot_serial_weight_avgService);
    }
    return this._ds_get_lot_serial_weight_avg;
  }
  private _ds_get_material_by_materialId: SalesOrders_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): SalesOrders_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(SalesOrders_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_kit_order_line_by_order_id_top1: SalesOrders_ds_get_material_kit_order_line_by_order_id_top1Service;
  public get ds_get_material_kit_order_line_by_order_id_top1(): SalesOrders_ds_get_material_kit_order_line_by_order_id_top1Service {
    if(!this._ds_get_material_kit_order_line_by_order_id_top1) {
      this._ds_get_material_kit_order_line_by_order_id_top1 = this.injector.get(SalesOrders_ds_get_material_kit_order_line_by_order_id_top1Service);
    }
    return this._ds_get_material_kit_order_line_by_order_id_top1;
  }
  private _ds_get_material_kit_order_lines_by_order_id: SalesOrders_ds_get_material_kit_order_lines_by_order_idService;
  public get ds_get_material_kit_order_lines_by_order_id(): SalesOrders_ds_get_material_kit_order_lines_by_order_idService {
    if(!this._ds_get_material_kit_order_lines_by_order_id) {
      this._ds_get_material_kit_order_lines_by_order_id = this.injector.get(SalesOrders_ds_get_material_kit_order_lines_by_order_idService);
    }
    return this._ds_get_material_kit_order_lines_by_order_id;
  }
  private _ds_get_material_packaging_by_materialId: SalesOrders_ds_get_material_packaging_by_materialIdService;
  public get ds_get_material_packaging_by_materialId(): SalesOrders_ds_get_material_packaging_by_materialIdService {
    if(!this._ds_get_material_packaging_by_materialId) {
      this._ds_get_material_packaging_by_materialId = this.injector.get(SalesOrders_ds_get_material_packaging_by_materialIdService);
    }
    return this._ds_get_material_packaging_by_materialId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: SalesOrders_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): SalesOrders_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(SalesOrders_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId_top1: SalesOrders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get ds_get_material_packaging_by_materialId_and_packagingId_top1(): SalesOrders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(SalesOrders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId: SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId(): SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId = this.injector.get(SalesOrders_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId;
  }
  private _ds_get_non_fixed_weight_details_by_shipmentId: SalesOrders_ds_get_non_fixed_weight_details_by_shipmentIdService;
  public get ds_get_non_fixed_weight_details_by_shipmentId(): SalesOrders_ds_get_non_fixed_weight_details_by_shipmentIdService {
    if(!this._ds_get_non_fixed_weight_details_by_shipmentId) {
      this._ds_get_non_fixed_weight_details_by_shipmentId = this.injector.get(SalesOrders_ds_get_non_fixed_weight_details_by_shipmentIdService);
    }
    return this._ds_get_non_fixed_weight_details_by_shipmentId;
  }
  private _ds_get_non_fixed_weight_details_by_shipmentId_completed: SalesOrders_ds_get_non_fixed_weight_details_by_shipmentId_completedService;
  public get ds_get_non_fixed_weight_details_by_shipmentId_completed(): SalesOrders_ds_get_non_fixed_weight_details_by_shipmentId_completedService {
    if(!this._ds_get_non_fixed_weight_details_by_shipmentId_completed) {
      this._ds_get_non_fixed_weight_details_by_shipmentId_completed = this.injector.get(SalesOrders_ds_get_non_fixed_weight_details_by_shipmentId_completedService);
    }
    return this._ds_get_non_fixed_weight_details_by_shipmentId_completed;
  }
  private _ds_get_order_account_address_by_orderId: SalesOrders_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): SalesOrders_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(SalesOrders_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: SalesOrders_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): SalesOrders_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(SalesOrders_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_aggregated_tasks: SalesOrders_ds_get_order_aggregated_tasksService;
  public get ds_get_order_aggregated_tasks(): SalesOrders_ds_get_order_aggregated_tasksService {
    if(!this._ds_get_order_aggregated_tasks) {
      this._ds_get_order_aggregated_tasks = this.injector.get(SalesOrders_ds_get_order_aggregated_tasksService);
    }
    return this._ds_get_order_aggregated_tasks;
  }
  private _ds_get_order_by_licenseplate: SalesOrders_ds_get_order_by_licenseplateService;
  public get ds_get_order_by_licenseplate(): SalesOrders_ds_get_order_by_licenseplateService {
    if(!this._ds_get_order_by_licenseplate) {
      this._ds_get_order_by_licenseplate = this.injector.get(SalesOrders_ds_get_order_by_licenseplateService);
    }
    return this._ds_get_order_by_licenseplate;
  }
  private _ds_get_order_by_lookupcode: SalesOrders_ds_get_order_by_lookupcodeService;
  public get ds_get_order_by_lookupcode(): SalesOrders_ds_get_order_by_lookupcodeService {
    if(!this._ds_get_order_by_lookupcode) {
      this._ds_get_order_by_lookupcode = this.injector.get(SalesOrders_ds_get_order_by_lookupcodeService);
    }
    return this._ds_get_order_by_lookupcode;
  }
  private _ds_get_order_by_orderId: SalesOrders_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): SalesOrders_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(SalesOrders_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_order_by_orderId_top1: SalesOrders_ds_get_order_by_orderId_top1Service;
  public get ds_get_order_by_orderId_top1(): SalesOrders_ds_get_order_by_orderId_top1Service {
    if(!this._ds_get_order_by_orderId_top1) {
      this._ds_get_order_by_orderId_top1 = this.injector.get(SalesOrders_ds_get_order_by_orderId_top1Service);
    }
    return this._ds_get_order_by_orderId_top1;
  }
  private _ds_get_order_lines_by_order_id_line_number: SalesOrders_ds_get_order_lines_by_order_id_line_numberService;
  public get ds_get_order_lines_by_order_id_line_number(): SalesOrders_ds_get_order_lines_by_order_id_line_numberService {
    if(!this._ds_get_order_lines_by_order_id_line_number) {
      this._ds_get_order_lines_by_order_id_line_number = this.injector.get(SalesOrders_ds_get_order_lines_by_order_id_line_numberService);
    }
    return this._ds_get_order_lines_by_order_id_line_number;
  }
  private _ds_get_order_lines_with_serial_numbers: SalesOrders_ds_get_order_lines_with_serial_numbersService;
  public get ds_get_order_lines_with_serial_numbers(): SalesOrders_ds_get_order_lines_with_serial_numbersService {
    if(!this._ds_get_order_lines_with_serial_numbers) {
      this._ds_get_order_lines_with_serial_numbers = this.injector.get(SalesOrders_ds_get_order_lines_with_serial_numbersService);
    }
    return this._ds_get_order_lines_with_serial_numbers;
  }
  private _ds_get_order_owner_contact_by_typeId: SalesOrders_ds_get_order_owner_contact_by_typeIdService;
  public get ds_get_order_owner_contact_by_typeId(): SalesOrders_ds_get_order_owner_contact_by_typeIdService {
    if(!this._ds_get_order_owner_contact_by_typeId) {
      this._ds_get_order_owner_contact_by_typeId = this.injector.get(SalesOrders_ds_get_order_owner_contact_by_typeIdService);
    }
    return this._ds_get_order_owner_contact_by_typeId;
  }
  private _ds_get_order_owner_contact_top1: SalesOrders_ds_get_order_owner_contact_top1Service;
  public get ds_get_order_owner_contact_top1(): SalesOrders_ds_get_order_owner_contact_top1Service {
    if(!this._ds_get_order_owner_contact_top1) {
      this._ds_get_order_owner_contact_top1 = this.injector.get(SalesOrders_ds_get_order_owner_contact_top1Service);
    }
    return this._ds_get_order_owner_contact_top1;
  }
  private _ds_get_order_ship_account_vs_order_address: SalesOrders_ds_get_order_ship_account_vs_order_addressService;
  public get ds_get_order_ship_account_vs_order_address(): SalesOrders_ds_get_order_ship_account_vs_order_addressService {
    if(!this._ds_get_order_ship_account_vs_order_address) {
      this._ds_get_order_ship_account_vs_order_address = this.injector.get(SalesOrders_ds_get_order_ship_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_account_vs_order_address;
  }
  private _ds_get_order_ship_to_account_vs_order_address: SalesOrders_ds_get_order_ship_to_account_vs_order_addressService;
  public get ds_get_order_ship_to_account_vs_order_address(): SalesOrders_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._ds_get_order_ship_to_account_vs_order_address) {
      this._ds_get_order_ship_to_account_vs_order_address = this.injector.get(SalesOrders_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address;
  }
  private _ds_get_order_signature_attachment: SalesOrders_ds_get_order_signature_attachmentService;
  public get ds_get_order_signature_attachment(): SalesOrders_ds_get_order_signature_attachmentService {
    if(!this._ds_get_order_signature_attachment) {
      this._ds_get_order_signature_attachment = this.injector.get(SalesOrders_ds_get_order_signature_attachmentService);
    }
    return this._ds_get_order_signature_attachment;
  }
  private _ds_get_order_total_picked_units_by_orderId: SalesOrders_ds_get_order_total_picked_units_by_orderIdService;
  public get ds_get_order_total_picked_units_by_orderId(): SalesOrders_ds_get_order_total_picked_units_by_orderIdService {
    if(!this._ds_get_order_total_picked_units_by_orderId) {
      this._ds_get_order_total_picked_units_by_orderId = this.injector.get(SalesOrders_ds_get_order_total_picked_units_by_orderIdService);
    }
    return this._ds_get_order_total_picked_units_by_orderId;
  }
  private _ds_get_order_total_picked_units_by_orderIds: SalesOrders_ds_get_order_total_picked_units_by_orderIdsService;
  public get ds_get_order_total_picked_units_by_orderIds(): SalesOrders_ds_get_order_total_picked_units_by_orderIdsService {
    if(!this._ds_get_order_total_picked_units_by_orderIds) {
      this._ds_get_order_total_picked_units_by_orderIds = this.injector.get(SalesOrders_ds_get_order_total_picked_units_by_orderIdsService);
    }
    return this._ds_get_order_total_picked_units_by_orderIds;
  }
  private _ds_get_order_total_picked_units_by_various: SalesOrders_ds_get_order_total_picked_units_by_variousService;
  public get ds_get_order_total_picked_units_by_various(): SalesOrders_ds_get_order_total_picked_units_by_variousService {
    if(!this._ds_get_order_total_picked_units_by_various) {
      this._ds_get_order_total_picked_units_by_various = this.injector.get(SalesOrders_ds_get_order_total_picked_units_by_variousService);
    }
    return this._ds_get_order_total_picked_units_by_various;
  }
  private _ds_get_order_total_picked_vs_expected_units_by_orderId: SalesOrders_ds_get_order_total_picked_vs_expected_units_by_orderIdService;
  public get ds_get_order_total_picked_vs_expected_units_by_orderId(): SalesOrders_ds_get_order_total_picked_vs_expected_units_by_orderIdService {
    if(!this._ds_get_order_total_picked_vs_expected_units_by_orderId) {
      this._ds_get_order_total_picked_vs_expected_units_by_orderId = this.injector.get(SalesOrders_ds_get_order_total_picked_vs_expected_units_by_orderIdService);
    }
    return this._ds_get_order_total_picked_vs_expected_units_by_orderId;
  }
  private _ds_get_order_total_units_by_orderId: SalesOrders_ds_get_order_total_units_by_orderIdService;
  public get ds_get_order_total_units_by_orderId(): SalesOrders_ds_get_order_total_units_by_orderIdService {
    if(!this._ds_get_order_total_units_by_orderId) {
      this._ds_get_order_total_units_by_orderId = this.injector.get(SalesOrders_ds_get_order_total_units_by_orderIdService);
    }
    return this._ds_get_order_total_units_by_orderId;
  }
  private _ds_get_order_total_units_by_orderIds: SalesOrders_ds_get_order_total_units_by_orderIdsService;
  public get ds_get_order_total_units_by_orderIds(): SalesOrders_ds_get_order_total_units_by_orderIdsService {
    if(!this._ds_get_order_total_units_by_orderIds) {
      this._ds_get_order_total_units_by_orderIds = this.injector.get(SalesOrders_ds_get_order_total_units_by_orderIdsService);
    }
    return this._ds_get_order_total_units_by_orderIds;
  }
  private _ds_get_order_warehouse_contact_top1: SalesOrders_ds_get_order_warehouse_contact_top1Service;
  public get ds_get_order_warehouse_contact_top1(): SalesOrders_ds_get_order_warehouse_contact_top1Service {
    if(!this._ds_get_order_warehouse_contact_top1) {
      this._ds_get_order_warehouse_contact_top1 = this.injector.get(SalesOrders_ds_get_order_warehouse_contact_top1Service);
    }
    return this._ds_get_order_warehouse_contact_top1;
  }
  private _ds_get_orderaddresses_by_orderId: SalesOrders_ds_get_orderaddresses_by_orderIdService;
  public get ds_get_orderaddresses_by_orderId(): SalesOrders_ds_get_orderaddresses_by_orderIdService {
    if(!this._ds_get_orderaddresses_by_orderId) {
      this._ds_get_orderaddresses_by_orderId = this.injector.get(SalesOrders_ds_get_orderaddresses_by_orderIdService);
    }
    return this._ds_get_orderaddresses_by_orderId;
  }
  private _ds_get_orderline_by_order_id_line_number: SalesOrders_ds_get_orderline_by_order_id_line_numberService;
  public get ds_get_orderline_by_order_id_line_number(): SalesOrders_ds_get_orderline_by_order_id_line_numberService {
    if(!this._ds_get_orderline_by_order_id_line_number) {
      this._ds_get_orderline_by_order_id_line_number = this.injector.get(SalesOrders_ds_get_orderline_by_order_id_line_numberService);
    }
    return this._ds_get_orderline_by_order_id_line_number;
  }
  private _ds_get_orderline_total_picked_units_by_orderId_linenumber: SalesOrders_ds_get_orderline_total_picked_units_by_orderId_linenumberService;
  public get ds_get_orderline_total_picked_units_by_orderId_linenumber(): SalesOrders_ds_get_orderline_total_picked_units_by_orderId_linenumberService {
    if(!this._ds_get_orderline_total_picked_units_by_orderId_linenumber) {
      this._ds_get_orderline_total_picked_units_by_orderId_linenumber = this.injector.get(SalesOrders_ds_get_orderline_total_picked_units_by_orderId_linenumberService);
    }
    return this._ds_get_orderline_total_picked_units_by_orderId_linenumber;
  }
  private _ds_get_orderlines_by_orderId: SalesOrders_ds_get_orderlines_by_orderIdService;
  public get ds_get_orderlines_by_orderId(): SalesOrders_ds_get_orderlines_by_orderIdService {
    if(!this._ds_get_orderlines_by_orderId) {
      this._ds_get_orderlines_by_orderId = this.injector.get(SalesOrders_ds_get_orderlines_by_orderIdService);
    }
    return this._ds_get_orderlines_by_orderId;
  }
  private _ds_get_orderlines_by_orderId_bottom1: SalesOrders_ds_get_orderlines_by_orderId_bottom1Service;
  public get ds_get_orderlines_by_orderId_bottom1(): SalesOrders_ds_get_orderlines_by_orderId_bottom1Service {
    if(!this._ds_get_orderlines_by_orderId_bottom1) {
      this._ds_get_orderlines_by_orderId_bottom1 = this.injector.get(SalesOrders_ds_get_orderlines_by_orderId_bottom1Service);
    }
    return this._ds_get_orderlines_by_orderId_bottom1;
  }
  private _ds_get_orderlines_by_orderId_linestatusids: SalesOrders_ds_get_orderlines_by_orderId_linestatusidsService;
  public get ds_get_orderlines_by_orderId_linestatusids(): SalesOrders_ds_get_orderlines_by_orderId_linestatusidsService {
    if(!this._ds_get_orderlines_by_orderId_linestatusids) {
      this._ds_get_orderlines_by_orderId_linestatusids = this.injector.get(SalesOrders_ds_get_orderlines_by_orderId_linestatusidsService);
    }
    return this._ds_get_orderlines_by_orderId_linestatusids;
  }
  private _ds_get_orderlines_by_orderId_without_children: SalesOrders_ds_get_orderlines_by_orderId_without_childrenService;
  public get ds_get_orderlines_by_orderId_without_children(): SalesOrders_ds_get_orderlines_by_orderId_without_childrenService {
    if(!this._ds_get_orderlines_by_orderId_without_children) {
      this._ds_get_orderlines_by_orderId_without_children = this.injector.get(SalesOrders_ds_get_orderlines_by_orderId_without_childrenService);
    }
    return this._ds_get_orderlines_by_orderId_without_children;
  }
  private _ds_get_orderlines_missing_shipment_by_orderId: SalesOrders_ds_get_orderlines_missing_shipment_by_orderIdService;
  public get ds_get_orderlines_missing_shipment_by_orderId(): SalesOrders_ds_get_orderlines_missing_shipment_by_orderIdService {
    if(!this._ds_get_orderlines_missing_shipment_by_orderId) {
      this._ds_get_orderlines_missing_shipment_by_orderId = this.injector.get(SalesOrders_ds_get_orderlines_missing_shipment_by_orderIdService);
    }
    return this._ds_get_orderlines_missing_shipment_by_orderId;
  }
  private _ds_get_orders_aggregated_tasks: SalesOrders_ds_get_orders_aggregated_tasksService;
  public get ds_get_orders_aggregated_tasks(): SalesOrders_ds_get_orders_aggregated_tasksService {
    if(!this._ds_get_orders_aggregated_tasks) {
      this._ds_get_orders_aggregated_tasks = this.injector.get(SalesOrders_ds_get_orders_aggregated_tasksService);
    }
    return this._ds_get_orders_aggregated_tasks;
  }
  private _ds_get_orders_by_shipmentId: SalesOrders_ds_get_orders_by_shipmentIdService;
  public get ds_get_orders_by_shipmentId(): SalesOrders_ds_get_orders_by_shipmentIdService {
    if(!this._ds_get_orders_by_shipmentId) {
      this._ds_get_orders_by_shipmentId = this.injector.get(SalesOrders_ds_get_orders_by_shipmentIdService);
    }
    return this._ds_get_orders_by_shipmentId;
  }
  private _ds_get_outbound_processing_strategies_by_projectId: SalesOrders_ds_get_outbound_processing_strategies_by_projectIdService;
  public get ds_get_outbound_processing_strategies_by_projectId(): SalesOrders_ds_get_outbound_processing_strategies_by_projectIdService {
    if(!this._ds_get_outbound_processing_strategies_by_projectId) {
      this._ds_get_outbound_processing_strategies_by_projectId = this.injector.get(SalesOrders_ds_get_outbound_processing_strategies_by_projectIdService);
    }
    return this._ds_get_outbound_processing_strategies_by_projectId;
  }
  private _ds_get_picking_tasks_by_orderId_orderLineNumbers: SalesOrders_ds_get_picking_tasks_by_orderId_orderLineNumbersService;
  public get ds_get_picking_tasks_by_orderId_orderLineNumbers(): SalesOrders_ds_get_picking_tasks_by_orderId_orderLineNumbersService {
    if(!this._ds_get_picking_tasks_by_orderId_orderLineNumbers) {
      this._ds_get_picking_tasks_by_orderId_orderLineNumbers = this.injector.get(SalesOrders_ds_get_picking_tasks_by_orderId_orderLineNumbersService);
    }
    return this._ds_get_picking_tasks_by_orderId_orderLineNumbers;
  }
  private _ds_get_picking_tasks_by_orderId_shipmentId: SalesOrders_ds_get_picking_tasks_by_orderId_shipmentIdService;
  public get ds_get_picking_tasks_by_orderId_shipmentId(): SalesOrders_ds_get_picking_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_picking_tasks_by_orderId_shipmentId) {
      this._ds_get_picking_tasks_by_orderId_shipmentId = this.injector.get(SalesOrders_ds_get_picking_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_picking_tasks_by_orderId_shipmentId;
  }
  private _ds_get_pickslips_by_waveId: SalesOrders_ds_get_pickslips_by_waveIdService;
  public get ds_get_pickslips_by_waveId(): SalesOrders_ds_get_pickslips_by_waveIdService {
    if(!this._ds_get_pickslips_by_waveId) {
      this._ds_get_pickslips_by_waveId = this.injector.get(SalesOrders_ds_get_pickslips_by_waveIdService);
    }
    return this._ds_get_pickslips_by_waveId;
  }
  private _ds_get_released_manual_allocations_by_orderId_shipmentId_top1: SalesOrders_ds_get_released_manual_allocations_by_orderId_shipmentId_top1Service;
  public get ds_get_released_manual_allocations_by_orderId_shipmentId_top1(): SalesOrders_ds_get_released_manual_allocations_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_released_manual_allocations_by_orderId_shipmentId_top1) {
      this._ds_get_released_manual_allocations_by_orderId_shipmentId_top1 = this.injector.get(SalesOrders_ds_get_released_manual_allocations_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_released_manual_allocations_by_orderId_shipmentId_top1;
  }
  private _ds_get_released_pick_tasks_by_chainhead: SalesOrders_ds_get_released_pick_tasks_by_chainheadService;
  public get ds_get_released_pick_tasks_by_chainhead(): SalesOrders_ds_get_released_pick_tasks_by_chainheadService {
    if(!this._ds_get_released_pick_tasks_by_chainhead) {
      this._ds_get_released_pick_tasks_by_chainhead = this.injector.get(SalesOrders_ds_get_released_pick_tasks_by_chainheadService);
    }
    return this._ds_get_released_pick_tasks_by_chainhead;
  }
  private _ds_get_released_picking_tasks_by_orderId_shipmentId_top1: SalesOrders_ds_get_released_picking_tasks_by_orderId_shipmentId_top1Service;
  public get ds_get_released_picking_tasks_by_orderId_shipmentId_top1(): SalesOrders_ds_get_released_picking_tasks_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_released_picking_tasks_by_orderId_shipmentId_top1) {
      this._ds_get_released_picking_tasks_by_orderId_shipmentId_top1 = this.injector.get(SalesOrders_ds_get_released_picking_tasks_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_released_picking_tasks_by_orderId_shipmentId_top1;
  }
  private _ds_get_serialnumber_by_serialnumberId: SalesOrders_ds_get_serialnumber_by_serialnumberIdService;
  public get ds_get_serialnumber_by_serialnumberId(): SalesOrders_ds_get_serialnumber_by_serialnumberIdService {
    if(!this._ds_get_serialnumber_by_serialnumberId) {
      this._ds_get_serialnumber_by_serialnumberId = this.injector.get(SalesOrders_ds_get_serialnumber_by_serialnumberIdService);
    }
    return this._ds_get_serialnumber_by_serialnumberId;
  }
  private _ds_get_serialnumbers_by_licenseplateId_lotId_packagingId: SalesOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_serialnumbers_by_licenseplateId_lotId_packagingId(): SalesOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId) {
      this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId = this.injector.get(SalesOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId: SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId(): SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId = this.injector.get(SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId: SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId(): SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId = this.injector.get(SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId: SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId(): SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId = this.injector.get(SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_shipmentId: SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_shipmentId(): SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId = this.injector.get(SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId: SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId(): SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId = this.injector.get(SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId: SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId(): SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId = this.injector.get(SalesOrders_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId;
  }
  private _ds_get_shipmentLines_by_orderId_shipmentId: SalesOrders_ds_get_shipmentLines_by_orderId_shipmentIdService;
  public get ds_get_shipmentLines_by_orderId_shipmentId(): SalesOrders_ds_get_shipmentLines_by_orderId_shipmentIdService {
    if(!this._ds_get_shipmentLines_by_orderId_shipmentId) {
      this._ds_get_shipmentLines_by_orderId_shipmentId = this.injector.get(SalesOrders_ds_get_shipmentLines_by_orderId_shipmentIdService);
    }
    return this._ds_get_shipmentLines_by_orderId_shipmentId;
  }
  private _ds_get_shipmentLines_by_orderId_top1: SalesOrders_ds_get_shipmentLines_by_orderId_top1Service;
  public get ds_get_shipmentLines_by_orderId_top1(): SalesOrders_ds_get_shipmentLines_by_orderId_top1Service {
    if(!this._ds_get_shipmentLines_by_orderId_top1) {
      this._ds_get_shipmentLines_by_orderId_top1 = this.injector.get(SalesOrders_ds_get_shipmentLines_by_orderId_top1Service);
    }
    return this._ds_get_shipmentLines_by_orderId_top1;
  }
  private _ds_get_shipmentLines_by_shipmentId: SalesOrders_ds_get_shipmentLines_by_shipmentIdService;
  public get ds_get_shipmentLines_by_shipmentId(): SalesOrders_ds_get_shipmentLines_by_shipmentIdService {
    if(!this._ds_get_shipmentLines_by_shipmentId) {
      this._ds_get_shipmentLines_by_shipmentId = this.injector.get(SalesOrders_ds_get_shipmentLines_by_shipmentIdService);
    }
    return this._ds_get_shipmentLines_by_shipmentId;
  }
  private _ds_get_shipmentLines_by_shipmentId_top1: SalesOrders_ds_get_shipmentLines_by_shipmentId_top1Service;
  public get ds_get_shipmentLines_by_shipmentId_top1(): SalesOrders_ds_get_shipmentLines_by_shipmentId_top1Service {
    if(!this._ds_get_shipmentLines_by_shipmentId_top1) {
      this._ds_get_shipmentLines_by_shipmentId_top1 = this.injector.get(SalesOrders_ds_get_shipmentLines_by_shipmentId_top1Service);
    }
    return this._ds_get_shipmentLines_by_shipmentId_top1;
  }
  private _ds_get_shipment_aggregated_licenseplates: SalesOrders_ds_get_shipment_aggregated_licenseplatesService;
  public get ds_get_shipment_aggregated_licenseplates(): SalesOrders_ds_get_shipment_aggregated_licenseplatesService {
    if(!this._ds_get_shipment_aggregated_licenseplates) {
      this._ds_get_shipment_aggregated_licenseplates = this.injector.get(SalesOrders_ds_get_shipment_aggregated_licenseplatesService);
    }
    return this._ds_get_shipment_aggregated_licenseplates;
  }
  private _ds_get_shipment_aggregated_tasks: SalesOrders_ds_get_shipment_aggregated_tasksService;
  public get ds_get_shipment_aggregated_tasks(): SalesOrders_ds_get_shipment_aggregated_tasksService {
    if(!this._ds_get_shipment_aggregated_tasks) {
      this._ds_get_shipment_aggregated_tasks = this.injector.get(SalesOrders_ds_get_shipment_aggregated_tasksService);
    }
    return this._ds_get_shipment_aggregated_tasks;
  }
  private _ds_get_shipment_by_orderId_top1: SalesOrders_ds_get_shipment_by_orderId_top1Service;
  public get ds_get_shipment_by_orderId_top1(): SalesOrders_ds_get_shipment_by_orderId_top1Service {
    if(!this._ds_get_shipment_by_orderId_top1) {
      this._ds_get_shipment_by_orderId_top1 = this.injector.get(SalesOrders_ds_get_shipment_by_orderId_top1Service);
    }
    return this._ds_get_shipment_by_orderId_top1;
  }
  private _ds_get_shipmentline_by_shipmentlineId: SalesOrders_ds_get_shipmentline_by_shipmentlineIdService;
  public get ds_get_shipmentline_by_shipmentlineId(): SalesOrders_ds_get_shipmentline_by_shipmentlineIdService {
    if(!this._ds_get_shipmentline_by_shipmentlineId) {
      this._ds_get_shipmentline_by_shipmentlineId = this.injector.get(SalesOrders_ds_get_shipmentline_by_shipmentlineIdService);
    }
    return this._ds_get_shipmentline_by_shipmentlineId;
  }
  private _ds_get_shipmentline_by_shipmentlineId_detailed: SalesOrders_ds_get_shipmentline_by_shipmentlineId_detailedService;
  public get ds_get_shipmentline_by_shipmentlineId_detailed(): SalesOrders_ds_get_shipmentline_by_shipmentlineId_detailedService {
    if(!this._ds_get_shipmentline_by_shipmentlineId_detailed) {
      this._ds_get_shipmentline_by_shipmentlineId_detailed = this.injector.get(SalesOrders_ds_get_shipmentline_by_shipmentlineId_detailedService);
    }
    return this._ds_get_shipmentline_by_shipmentlineId_detailed;
  }
  private _ds_get_shipmentlines_by_orderId_bottom1: SalesOrders_ds_get_shipmentlines_by_orderId_bottom1Service;
  public get ds_get_shipmentlines_by_orderId_bottom1(): SalesOrders_ds_get_shipmentlines_by_orderId_bottom1Service {
    if(!this._ds_get_shipmentlines_by_orderId_bottom1) {
      this._ds_get_shipmentlines_by_orderId_bottom1 = this.injector.get(SalesOrders_ds_get_shipmentlines_by_orderId_bottom1Service);
    }
    return this._ds_get_shipmentlines_by_orderId_bottom1;
  }
  private _ds_get_shipments_aggregated_licenseplates: SalesOrders_ds_get_shipments_aggregated_licenseplatesService;
  public get ds_get_shipments_aggregated_licenseplates(): SalesOrders_ds_get_shipments_aggregated_licenseplatesService {
    if(!this._ds_get_shipments_aggregated_licenseplates) {
      this._ds_get_shipments_aggregated_licenseplates = this.injector.get(SalesOrders_ds_get_shipments_aggregated_licenseplatesService);
    }
    return this._ds_get_shipments_aggregated_licenseplates;
  }
  private _ds_get_shipments_aggregated_tasks: SalesOrders_ds_get_shipments_aggregated_tasksService;
  public get ds_get_shipments_aggregated_tasks(): SalesOrders_ds_get_shipments_aggregated_tasksService {
    if(!this._ds_get_shipments_aggregated_tasks) {
      this._ds_get_shipments_aggregated_tasks = this.injector.get(SalesOrders_ds_get_shipments_aggregated_tasksService);
    }
    return this._ds_get_shipments_aggregated_tasks;
  }
  private _ds_get_shipments_by_orderId: SalesOrders_ds_get_shipments_by_orderIdService;
  public get ds_get_shipments_by_orderId(): SalesOrders_ds_get_shipments_by_orderIdService {
    if(!this._ds_get_shipments_by_orderId) {
      this._ds_get_shipments_by_orderId = this.injector.get(SalesOrders_ds_get_shipments_by_orderIdService);
    }
    return this._ds_get_shipments_by_orderId;
  }
  private _ds_get_shipments_by_shipmentId: SalesOrders_ds_get_shipments_by_shipmentIdService;
  public get ds_get_shipments_by_shipmentId(): SalesOrders_ds_get_shipments_by_shipmentIdService {
    if(!this._ds_get_shipments_by_shipmentId) {
      this._ds_get_shipments_by_shipmentId = this.injector.get(SalesOrders_ds_get_shipments_by_shipmentIdService);
    }
    return this._ds_get_shipments_by_shipmentId;
  }
  private _ds_get_shipments_from_orders: SalesOrders_ds_get_shipments_from_ordersService;
  public get ds_get_shipments_from_orders(): SalesOrders_ds_get_shipments_from_ordersService {
    if(!this._ds_get_shipments_from_orders) {
      this._ds_get_shipments_from_orders = this.injector.get(SalesOrders_ds_get_shipments_from_ordersService);
    }
    return this._ds_get_shipments_from_orders;
  }
  private _ds_get_shipmentscontents_by_orderId_shipmentId_top1: SalesOrders_ds_get_shipmentscontents_by_orderId_shipmentId_top1Service;
  public get ds_get_shipmentscontents_by_orderId_shipmentId_top1(): SalesOrders_ds_get_shipmentscontents_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_shipmentscontents_by_orderId_shipmentId_top1) {
      this._ds_get_shipmentscontents_by_orderId_shipmentId_top1 = this.injector.get(SalesOrders_ds_get_shipmentscontents_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_shipmentscontents_by_orderId_shipmentId_top1;
  }
  private _ds_get_shippingcontainers_by_shipmentId: SalesOrders_ds_get_shippingcontainers_by_shipmentIdService;
  public get ds_get_shippingcontainers_by_shipmentId(): SalesOrders_ds_get_shippingcontainers_by_shipmentIdService {
    if(!this._ds_get_shippingcontainers_by_shipmentId) {
      this._ds_get_shippingcontainers_by_shipmentId = this.injector.get(SalesOrders_ds_get_shippingcontainers_by_shipmentIdService);
    }
    return this._ds_get_shippingcontainers_by_shipmentId;
  }
  private _ds_get_shippingcontents_by_licenseplateId: SalesOrders_ds_get_shippingcontents_by_licenseplateIdService;
  public get ds_get_shippingcontents_by_licenseplateId(): SalesOrders_ds_get_shippingcontents_by_licenseplateIdService {
    if(!this._ds_get_shippingcontents_by_licenseplateId) {
      this._ds_get_shippingcontents_by_licenseplateId = this.injector.get(SalesOrders_ds_get_shippingcontents_by_licenseplateIdService);
    }
    return this._ds_get_shippingcontents_by_licenseplateId;
  }
  private _ds_get_shippingcontents_by_shipmentId_orderId: SalesOrders_ds_get_shippingcontents_by_shipmentId_orderIdService;
  public get ds_get_shippingcontents_by_shipmentId_orderId(): SalesOrders_ds_get_shippingcontents_by_shipmentId_orderIdService {
    if(!this._ds_get_shippingcontents_by_shipmentId_orderId) {
      this._ds_get_shippingcontents_by_shipmentId_orderId = this.injector.get(SalesOrders_ds_get_shippingcontents_by_shipmentId_orderIdService);
    }
    return this._ds_get_shippingcontents_by_shipmentId_orderId;
  }
  private _ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId: SalesOrders_ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateIdService;
  public get ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId(): SalesOrders_ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateIdService {
    if(!this._ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId) {
      this._ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId = this.injector.get(SalesOrders_ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateIdService);
    }
    return this._ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId;
  }
  private _ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId: SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService;
  public get ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId(): SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService {
    if(!this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId) {
      this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId = this.injector.get(SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService);
    }
    return this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId;
  }
  private _ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId: SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedIdService;
  public get ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId(): SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedIdService {
    if(!this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId) {
      this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId = this.injector.get(SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedIdService);
    }
    return this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId;
  }
  private _ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId: SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService;
  public get ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId(): SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService {
    if(!this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId) {
      this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId = this.injector.get(SalesOrders_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService);
    }
    return this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId;
  }
  private _ds_get_task_by_taskId: SalesOrders_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): SalesOrders_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(SalesOrders_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_tasks_by_licenseplateId: SalesOrders_ds_get_tasks_by_licenseplateIdService;
  public get ds_get_tasks_by_licenseplateId(): SalesOrders_ds_get_tasks_by_licenseplateIdService {
    if(!this._ds_get_tasks_by_licenseplateId) {
      this._ds_get_tasks_by_licenseplateId = this.injector.get(SalesOrders_ds_get_tasks_by_licenseplateIdService);
    }
    return this._ds_get_tasks_by_licenseplateId;
  }
  private _ds_get_tasks_by_orderId_orderLineNumbers: SalesOrders_ds_get_tasks_by_orderId_orderLineNumbersService;
  public get ds_get_tasks_by_orderId_orderLineNumbers(): SalesOrders_ds_get_tasks_by_orderId_orderLineNumbersService {
    if(!this._ds_get_tasks_by_orderId_orderLineNumbers) {
      this._ds_get_tasks_by_orderId_orderLineNumbers = this.injector.get(SalesOrders_ds_get_tasks_by_orderId_orderLineNumbersService);
    }
    return this._ds_get_tasks_by_orderId_orderLineNumbers;
  }
  private _ds_get_tasks_by_orderId_shipmentId: SalesOrders_ds_get_tasks_by_orderId_shipmentIdService;
  public get ds_get_tasks_by_orderId_shipmentId(): SalesOrders_ds_get_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_tasks_by_orderId_shipmentId) {
      this._ds_get_tasks_by_orderId_shipmentId = this.injector.get(SalesOrders_ds_get_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_tasks_by_orderId_shipmentId;
  }
  private _ds_get_tasks_by_shipmentId: SalesOrders_ds_get_tasks_by_shipmentIdService;
  public get ds_get_tasks_by_shipmentId(): SalesOrders_ds_get_tasks_by_shipmentIdService {
    if(!this._ds_get_tasks_by_shipmentId) {
      this._ds_get_tasks_by_shipmentId = this.injector.get(SalesOrders_ds_get_tasks_by_shipmentIdService);
    }
    return this._ds_get_tasks_by_shipmentId;
  }
  private _ds_get_warehouse_by_warehouseId: SalesOrders_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): SalesOrders_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(SalesOrders_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_get_warehouses_by_warehouseIds: SalesOrders_ds_get_warehouses_by_warehouseIdsService;
  public get ds_get_warehouses_by_warehouseIds(): SalesOrders_ds_get_warehouses_by_warehouseIdsService {
    if(!this._ds_get_warehouses_by_warehouseIds) {
      this._ds_get_warehouses_by_warehouseIds = this.injector.get(SalesOrders_ds_get_warehouses_by_warehouseIdsService);
    }
    return this._ds_get_warehouses_by_warehouseIds;
  }
  private _ds_get_wave_by_waveId: SalesOrders_ds_get_wave_by_waveIdService;
  public get ds_get_wave_by_waveId(): SalesOrders_ds_get_wave_by_waveIdService {
    if(!this._ds_get_wave_by_waveId) {
      this._ds_get_wave_by_waveId = this.injector.get(SalesOrders_ds_get_wave_by_waveIdService);
    }
    return this._ds_get_wave_by_waveId;
  }
  private _ds_license_plate_labels_by_order_report: SalesOrders_ds_license_plate_labels_by_order_reportService;
  public get ds_license_plate_labels_by_order_report(): SalesOrders_ds_license_plate_labels_by_order_reportService {
    if(!this._ds_license_plate_labels_by_order_report) {
      this._ds_license_plate_labels_by_order_report = this.injector.get(SalesOrders_ds_license_plate_labels_by_order_reportService);
    }
    return this._ds_license_plate_labels_by_order_report;
  }
  private _ds_licenseplates_by_locationId_lotId_packagingId_dd: SalesOrders_ds_licenseplates_by_locationId_lotId_packagingId_ddService;
  public get ds_licenseplates_by_locationId_lotId_packagingId_dd(): SalesOrders_ds_licenseplates_by_locationId_lotId_packagingId_ddService {
    if(!this._ds_licenseplates_by_locationId_lotId_packagingId_dd) {
      this._ds_licenseplates_by_locationId_lotId_packagingId_dd = this.injector.get(SalesOrders_ds_licenseplates_by_locationId_lotId_packagingId_ddService);
    }
    return this._ds_licenseplates_by_locationId_lotId_packagingId_dd;
  }
  private _ds_load_slip_order_lines_report: SalesOrders_ds_load_slip_order_lines_reportService;
  public get ds_load_slip_order_lines_report(): SalesOrders_ds_load_slip_order_lines_reportService {
    if(!this._ds_load_slip_order_lines_report) {
      this._ds_load_slip_order_lines_report = this.injector.get(SalesOrders_ds_load_slip_order_lines_reportService);
    }
    return this._ds_load_slip_order_lines_report;
  }
  private _ds_locations_eligible_for_allocation_dd: SalesOrders_ds_locations_eligible_for_allocation_ddService;
  public get ds_locations_eligible_for_allocation_dd(): SalesOrders_ds_locations_eligible_for_allocation_ddService {
    if(!this._ds_locations_eligible_for_allocation_dd) {
      this._ds_locations_eligible_for_allocation_dd = this.injector.get(SalesOrders_ds_locations_eligible_for_allocation_ddService);
    }
    return this._ds_locations_eligible_for_allocation_dd;
  }
  private _ds_manual_allocations_grid: SalesOrders_ds_manual_allocations_gridService;
  public get ds_manual_allocations_grid(): SalesOrders_ds_manual_allocations_gridService {
    if(!this._ds_manual_allocations_grid) {
      this._ds_manual_allocations_grid = this.injector.get(SalesOrders_ds_manual_allocations_gridService);
    }
    return this._ds_manual_allocations_grid;
  }
  private _ds_order_loaded_percentage_widget: SalesOrders_ds_order_loaded_percentage_widgetService;
  public get ds_order_loaded_percentage_widget(): SalesOrders_ds_order_loaded_percentage_widgetService {
    if(!this._ds_order_loaded_percentage_widget) {
      this._ds_order_loaded_percentage_widget = this.injector.get(SalesOrders_ds_order_loaded_percentage_widgetService);
    }
    return this._ds_order_loaded_percentage_widget;
  }
  private _ds_order_picked_percentage_widget: SalesOrders_ds_order_picked_percentage_widgetService;
  public get ds_order_picked_percentage_widget(): SalesOrders_ds_order_picked_percentage_widgetService {
    if(!this._ds_order_picked_percentage_widget) {
      this._ds_order_picked_percentage_widget = this.injector.get(SalesOrders_ds_order_picked_percentage_widgetService);
    }
    return this._ds_order_picked_percentage_widget;
  }
  private _ds_order_total_completed_order_count_by_date: SalesOrders_ds_order_total_completed_order_count_by_dateService;
  public get ds_order_total_completed_order_count_by_date(): SalesOrders_ds_order_total_completed_order_count_by_dateService {
    if(!this._ds_order_total_completed_order_count_by_date) {
      this._ds_order_total_completed_order_count_by_date = this.injector.get(SalesOrders_ds_order_total_completed_order_count_by_dateService);
    }
    return this._ds_order_total_completed_order_count_by_date;
  }
  private _ds_order_total_completed_order_count_by_date_widget: SalesOrders_ds_order_total_completed_order_count_by_date_widgetService;
  public get ds_order_total_completed_order_count_by_date_widget(): SalesOrders_ds_order_total_completed_order_count_by_date_widgetService {
    if(!this._ds_order_total_completed_order_count_by_date_widget) {
      this._ds_order_total_completed_order_count_by_date_widget = this.injector.get(SalesOrders_ds_order_total_completed_order_count_by_date_widgetService);
    }
    return this._ds_order_total_completed_order_count_by_date_widget;
  }
  private _ds_order_total_pallet_count_widget: SalesOrders_ds_order_total_pallet_count_widgetService;
  public get ds_order_total_pallet_count_widget(): SalesOrders_ds_order_total_pallet_count_widgetService {
    if(!this._ds_order_total_pallet_count_widget) {
      this._ds_order_total_pallet_count_widget = this.injector.get(SalesOrders_ds_order_total_pallet_count_widgetService);
    }
    return this._ds_order_total_pallet_count_widget;
  }
  private _ds_order_total_picked_gross_by_date: SalesOrders_ds_order_total_picked_gross_by_dateService;
  public get ds_order_total_picked_gross_by_date(): SalesOrders_ds_order_total_picked_gross_by_dateService {
    if(!this._ds_order_total_picked_gross_by_date) {
      this._ds_order_total_picked_gross_by_date = this.injector.get(SalesOrders_ds_order_total_picked_gross_by_dateService);
    }
    return this._ds_order_total_picked_gross_by_date;
  }
  private _ds_order_total_picked_gross_by_date_widget: SalesOrders_ds_order_total_picked_gross_by_date_widgetService;
  public get ds_order_total_picked_gross_by_date_widget(): SalesOrders_ds_order_total_picked_gross_by_date_widgetService {
    if(!this._ds_order_total_picked_gross_by_date_widget) {
      this._ds_order_total_picked_gross_by_date_widget = this.injector.get(SalesOrders_ds_order_total_picked_gross_by_date_widgetService);
    }
    return this._ds_order_total_picked_gross_by_date_widget;
  }
  private _ds_order_total_picked_gross_widget: SalesOrders_ds_order_total_picked_gross_widgetService;
  public get ds_order_total_picked_gross_widget(): SalesOrders_ds_order_total_picked_gross_widgetService {
    if(!this._ds_order_total_picked_gross_widget) {
      this._ds_order_total_picked_gross_widget = this.injector.get(SalesOrders_ds_order_total_picked_gross_widgetService);
    }
    return this._ds_order_total_picked_gross_widget;
  }
  private _ds_order_total_picked_order_count_by_date: SalesOrders_ds_order_total_picked_order_count_by_dateService;
  public get ds_order_total_picked_order_count_by_date(): SalesOrders_ds_order_total_picked_order_count_by_dateService {
    if(!this._ds_order_total_picked_order_count_by_date) {
      this._ds_order_total_picked_order_count_by_date = this.injector.get(SalesOrders_ds_order_total_picked_order_count_by_dateService);
    }
    return this._ds_order_total_picked_order_count_by_date;
  }
  private _ds_order_total_picked_order_count_by_date_widget: SalesOrders_ds_order_total_picked_order_count_by_date_widgetService;
  public get ds_order_total_picked_order_count_by_date_widget(): SalesOrders_ds_order_total_picked_order_count_by_date_widgetService {
    if(!this._ds_order_total_picked_order_count_by_date_widget) {
      this._ds_order_total_picked_order_count_by_date_widget = this.injector.get(SalesOrders_ds_order_total_picked_order_count_by_date_widgetService);
    }
    return this._ds_order_total_picked_order_count_by_date_widget;
  }
  private _ds_order_total_picked_units: SalesOrders_ds_order_total_picked_unitsService;
  public get ds_order_total_picked_units(): SalesOrders_ds_order_total_picked_unitsService {
    if(!this._ds_order_total_picked_units) {
      this._ds_order_total_picked_units = this.injector.get(SalesOrders_ds_order_total_picked_unitsService);
    }
    return this._ds_order_total_picked_units;
  }
  private _ds_order_total_picked_units_by_date: SalesOrders_ds_order_total_picked_units_by_dateService;
  public get ds_order_total_picked_units_by_date(): SalesOrders_ds_order_total_picked_units_by_dateService {
    if(!this._ds_order_total_picked_units_by_date) {
      this._ds_order_total_picked_units_by_date = this.injector.get(SalesOrders_ds_order_total_picked_units_by_dateService);
    }
    return this._ds_order_total_picked_units_by_date;
  }
  private _ds_order_total_picked_units_by_date_widget: SalesOrders_ds_order_total_picked_units_by_date_widgetService;
  public get ds_order_total_picked_units_by_date_widget(): SalesOrders_ds_order_total_picked_units_by_date_widgetService {
    if(!this._ds_order_total_picked_units_by_date_widget) {
      this._ds_order_total_picked_units_by_date_widget = this.injector.get(SalesOrders_ds_order_total_picked_units_by_date_widgetService);
    }
    return this._ds_order_total_picked_units_by_date_widget;
  }
  private _ds_order_total_picked_units_widget: SalesOrders_ds_order_total_picked_units_widgetService;
  public get ds_order_total_picked_units_widget(): SalesOrders_ds_order_total_picked_units_widgetService {
    if(!this._ds_order_total_picked_units_widget) {
      this._ds_order_total_picked_units_widget = this.injector.get(SalesOrders_ds_order_total_picked_units_widgetService);
    }
    return this._ds_order_total_picked_units_widget;
  }
  private _ds_order_total_picked_weight_by_orderId_lotId_packagedId: SalesOrders_ds_order_total_picked_weight_by_orderId_lotId_packagedIdService;
  public get ds_order_total_picked_weight_by_orderId_lotId_packagedId(): SalesOrders_ds_order_total_picked_weight_by_orderId_lotId_packagedIdService {
    if(!this._ds_order_total_picked_weight_by_orderId_lotId_packagedId) {
      this._ds_order_total_picked_weight_by_orderId_lotId_packagedId = this.injector.get(SalesOrders_ds_order_total_picked_weight_by_orderId_lotId_packagedIdService);
    }
    return this._ds_order_total_picked_weight_by_orderId_lotId_packagedId;
  }
  private _ds_order_total_picked_weight_by_orderId_materialId: SalesOrders_ds_order_total_picked_weight_by_orderId_materialIdService;
  public get ds_order_total_picked_weight_by_orderId_materialId(): SalesOrders_ds_order_total_picked_weight_by_orderId_materialIdService {
    if(!this._ds_order_total_picked_weight_by_orderId_materialId) {
      this._ds_order_total_picked_weight_by_orderId_materialId = this.injector.get(SalesOrders_ds_order_total_picked_weight_by_orderId_materialIdService);
    }
    return this._ds_order_total_picked_weight_by_orderId_materialId;
  }
  private _ds_order_total_units_widget: SalesOrders_ds_order_total_units_widgetService;
  public get ds_order_total_units_widget(): SalesOrders_ds_order_total_units_widgetService {
    if(!this._ds_order_total_units_widget) {
      this._ds_order_total_units_widget = this.injector.get(SalesOrders_ds_order_total_units_widgetService);
    }
    return this._ds_order_total_units_widget;
  }
  private _ds_orderclasses_dd: SalesOrders_ds_orderclasses_ddService;
  public get ds_orderclasses_dd(): SalesOrders_ds_orderclasses_ddService {
    if(!this._ds_orderclasses_dd) {
      this._ds_orderclasses_dd = this.injector.get(SalesOrders_ds_orderclasses_ddService);
    }
    return this._ds_orderclasses_dd;
  }
  private _ds_orderstatuses_dd: SalesOrders_ds_orderstatuses_ddService;
  public get ds_orderstatuses_dd(): SalesOrders_ds_orderstatuses_ddService {
    if(!this._ds_orderstatuses_dd) {
      this._ds_orderstatuses_dd = this.injector.get(SalesOrders_ds_orderstatuses_ddService);
    }
    return this._ds_orderstatuses_dd;
  }
  private _ds_outbound_details_bill_of_lading_report: SalesOrders_ds_outbound_details_bill_of_lading_reportService;
  public get ds_outbound_details_bill_of_lading_report(): SalesOrders_ds_outbound_details_bill_of_lading_reportService {
    if(!this._ds_outbound_details_bill_of_lading_report) {
      this._ds_outbound_details_bill_of_lading_report = this.injector.get(SalesOrders_ds_outbound_details_bill_of_lading_reportService);
    }
    return this._ds_outbound_details_bill_of_lading_report;
  }
  private _ds_outbound_details_bill_of_lading_report_advanced: SalesOrders_ds_outbound_details_bill_of_lading_report_advancedService;
  public get ds_outbound_details_bill_of_lading_report_advanced(): SalesOrders_ds_outbound_details_bill_of_lading_report_advancedService {
    if(!this._ds_outbound_details_bill_of_lading_report_advanced) {
      this._ds_outbound_details_bill_of_lading_report_advanced = this.injector.get(SalesOrders_ds_outbound_details_bill_of_lading_report_advancedService);
    }
    return this._ds_outbound_details_bill_of_lading_report_advanced;
  }
  private _ds_outbound_header_bill_of_lading_report: SalesOrders_ds_outbound_header_bill_of_lading_reportService;
  public get ds_outbound_header_bill_of_lading_report(): SalesOrders_ds_outbound_header_bill_of_lading_reportService {
    if(!this._ds_outbound_header_bill_of_lading_report) {
      this._ds_outbound_header_bill_of_lading_report = this.injector.get(SalesOrders_ds_outbound_header_bill_of_lading_reportService);
    }
    return this._ds_outbound_header_bill_of_lading_report;
  }
  private _ds_outbound_orders_details_grid: SalesOrders_ds_outbound_orders_details_gridService;
  public get ds_outbound_orders_details_grid(): SalesOrders_ds_outbound_orders_details_gridService {
    if(!this._ds_outbound_orders_details_grid) {
      this._ds_outbound_orders_details_grid = this.injector.get(SalesOrders_ds_outbound_orders_details_gridService);
    }
    return this._ds_outbound_orders_details_grid;
  }
  private _ds_outbound_orders_grid: SalesOrders_ds_outbound_orders_gridService;
  public get ds_outbound_orders_grid(): SalesOrders_ds_outbound_orders_gridService {
    if(!this._ds_outbound_orders_grid) {
      this._ds_outbound_orders_grid = this.injector.get(SalesOrders_ds_outbound_orders_gridService);
    }
    return this._ds_outbound_orders_grid;
  }
  private _ds_outbound_pick_slip_by_orderId_report: SalesOrders_ds_outbound_pick_slip_by_orderId_reportService;
  public get ds_outbound_pick_slip_by_orderId_report(): SalesOrders_ds_outbound_pick_slip_by_orderId_reportService {
    if(!this._ds_outbound_pick_slip_by_orderId_report) {
      this._ds_outbound_pick_slip_by_orderId_report = this.injector.get(SalesOrders_ds_outbound_pick_slip_by_orderId_reportService);
    }
    return this._ds_outbound_pick_slip_by_orderId_report;
  }
  private _ds_packing_list_report_details: SalesOrders_ds_packing_list_report_detailsService;
  public get ds_packing_list_report_details(): SalesOrders_ds_packing_list_report_detailsService {
    if(!this._ds_packing_list_report_details) {
      this._ds_packing_list_report_details = this.injector.get(SalesOrders_ds_packing_list_report_detailsService);
    }
    return this._ds_packing_list_report_details;
  }
  private _ds_packing_list_report_header: SalesOrders_ds_packing_list_report_headerService;
  public get ds_packing_list_report_header(): SalesOrders_ds_packing_list_report_headerService {
    if(!this._ds_packing_list_report_header) {
      this._ds_packing_list_report_header = this.injector.get(SalesOrders_ds_packing_list_report_headerService);
    }
    return this._ds_packing_list_report_header;
  }
  private _ds_processing_strategy_workflows_dd: SalesOrders_ds_processing_strategy_workflows_ddService;
  public get ds_processing_strategy_workflows_dd(): SalesOrders_ds_processing_strategy_workflows_ddService {
    if(!this._ds_processing_strategy_workflows_dd) {
      this._ds_processing_strategy_workflows_dd = this.injector.get(SalesOrders_ds_processing_strategy_workflows_ddService);
    }
    return this._ds_processing_strategy_workflows_dd;
  }
  private _ds_sales_order_editor: SalesOrders_ds_sales_order_editorService;
  public get ds_sales_order_editor(): SalesOrders_ds_sales_order_editorService {
    if(!this._ds_sales_order_editor) {
      this._ds_sales_order_editor = this.injector.get(SalesOrders_ds_sales_order_editorService);
    }
    return this._ds_sales_order_editor;
  }
  private _ds_sales_order_lines_grid: SalesOrders_ds_sales_order_lines_gridService;
  public get ds_sales_order_lines_grid(): SalesOrders_ds_sales_order_lines_gridService {
    if(!this._ds_sales_order_lines_grid) {
      this._ds_sales_order_lines_grid = this.injector.get(SalesOrders_ds_sales_order_lines_gridService);
    }
    return this._ds_sales_order_lines_grid;
  }
  private _ds_sales_order_picking_grid: SalesOrders_ds_sales_order_picking_gridService;
  public get ds_sales_order_picking_grid(): SalesOrders_ds_sales_order_picking_gridService {
    if(!this._ds_sales_order_picking_grid) {
      this._ds_sales_order_picking_grid = this.injector.get(SalesOrders_ds_sales_order_picking_gridService);
    }
    return this._ds_sales_order_picking_grid;
  }
  private _ds_sales_orders_children_tasks_grid: SalesOrders_ds_sales_orders_children_tasks_gridService;
  public get ds_sales_orders_children_tasks_grid(): SalesOrders_ds_sales_orders_children_tasks_gridService {
    if(!this._ds_sales_orders_children_tasks_grid) {
      this._ds_sales_orders_children_tasks_grid = this.injector.get(SalesOrders_ds_sales_orders_children_tasks_gridService);
    }
    return this._ds_sales_orders_children_tasks_grid;
  }
  private _ds_sales_orders_tasks_grid: SalesOrders_ds_sales_orders_tasks_gridService;
  public get ds_sales_orders_tasks_grid(): SalesOrders_ds_sales_orders_tasks_gridService {
    if(!this._ds_sales_orders_tasks_grid) {
      this._ds_sales_orders_tasks_grid = this.injector.get(SalesOrders_ds_sales_orders_tasks_gridService);
    }
    return this._ds_sales_orders_tasks_grid;
  }
  private _ds_shipment_archived_contents: SalesOrders_ds_shipment_archived_contentsService;
  public get ds_shipment_archived_contents(): SalesOrders_ds_shipment_archived_contentsService {
    if(!this._ds_shipment_archived_contents) {
      this._ds_shipment_archived_contents = this.injector.get(SalesOrders_ds_shipment_archived_contentsService);
    }
    return this._ds_shipment_archived_contents;
  }
  private _ds_shipment_contents: SalesOrders_ds_shipment_contentsService;
  public get ds_shipment_contents(): SalesOrders_ds_shipment_contentsService {
    if(!this._ds_shipment_contents) {
      this._ds_shipment_contents = this.injector.get(SalesOrders_ds_shipment_contentsService);
    }
    return this._ds_shipment_contents;
  }
  private _ds_shipment_expected: SalesOrders_ds_shipment_expectedService;
  public get ds_shipment_expected(): SalesOrders_ds_shipment_expectedService {
    if(!this._ds_shipment_expected) {
      this._ds_shipment_expected = this.injector.get(SalesOrders_ds_shipment_expectedService);
    }
    return this._ds_shipment_expected;
  }
  private _ds_shipment_priorities_dd: SalesOrders_ds_shipment_priorities_ddService;
  public get ds_shipment_priorities_dd(): SalesOrders_ds_shipment_priorities_ddService {
    if(!this._ds_shipment_priorities_dd) {
      this._ds_shipment_priorities_dd = this.injector.get(SalesOrders_ds_shipment_priorities_ddService);
    }
    return this._ds_shipment_priorities_dd;
  }
  private _ds_shipment_processed: SalesOrders_ds_shipment_processedService;
  public get ds_shipment_processed(): SalesOrders_ds_shipment_processedService {
    if(!this._ds_shipment_processed) {
      this._ds_shipment_processed = this.injector.get(SalesOrders_ds_shipment_processedService);
    }
    return this._ds_shipment_processed;
  }
  private _ds_shippingcontents_grid: SalesOrders_ds_shippingcontents_gridService;
  public get ds_shippingcontents_grid(): SalesOrders_ds_shippingcontents_gridService {
    if(!this._ds_shippingcontents_grid) {
      this._ds_shippingcontents_grid = this.injector.get(SalesOrders_ds_shippingcontents_gridService);
    }
    return this._ds_shippingcontents_grid;
  }
  private _ds_wave_priorities_dd: SalesOrders_ds_wave_priorities_ddService;
  public get ds_wave_priorities_dd(): SalesOrders_ds_wave_priorities_ddService {
    if(!this._ds_wave_priorities_dd) {
      this._ds_wave_priorities_dd = this.injector.get(SalesOrders_ds_wave_priorities_ddService);
    }
    return this._ds_wave_priorities_dd;
  }
  private _testing_flow_based_data_source_for_grids: SalesOrders_testing_flow_based_data_source_for_gridsService;
  public get testing_flow_based_data_source_for_grids(): SalesOrders_testing_flow_based_data_source_for_gridsService {
    if(!this._testing_flow_based_data_source_for_grids) {
      this._testing_flow_based_data_source_for_grids = this.injector.get(SalesOrders_testing_flow_based_data_source_for_gridsService);
    }
    return this._testing_flow_based_data_source_for_grids;
  }
}

