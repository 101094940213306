import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_serialNumbers_by_lpServiceInParams {
  licensePlateId: number}

interface IReports_custom_serialNumbers_by_lpServiceData {
  SerialNumbers?: { result?: { Id?: number, LookupCode?: string, SerialNumbers?: { Id?: number, Archived?: boolean, LookupCode?: string, PackagingId?: number, ChildSerialNumbers?: { LookupCode?: string, ChildSerialNumbers?: { LookupCode?: string, PackagingId?: number }[] }[] }[] }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_serialNumbers_by_lpService extends ReportBaseService<IReports_custom_serialNumbers_by_lpServiceInParams, IReports_custom_serialNumbers_by_lpServiceData> {

  protected reportReferenceName = 'custom_serialNumbers_by_lp';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_serialNumbers_by_lpServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_serialNumbers_by_lpServiceInParams): Promise<IReports_custom_serialNumbers_by_lpServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_serialNumbers_by_lpServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_serialNumbers_by_lpServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        licensePlateId:  $report.inParams.licensePlateId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_get_serial_numbers_by_pallet_id.get(dsParams);
      
      data.SerialNumbers = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
