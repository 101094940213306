<div class="dataview tab-report" *ngIf="initialized && !$hasMissingRequiredInParams">

  <div class="datex-report full-size-report-container">
    <gc-activereports-viewer *ngIf="report"
                         #reportViewer
                         (init)="onViewerInit()"></gc-activereports-viewer>
  </div>
</div>

<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
