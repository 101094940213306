import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PackVerification_ds_get_serialnumber_by_serialNumberIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { serialNumberId: number }): 
  Promise<{ result: { Id?: number, GrossVolume?: number, GrossWeight?: number, NetVolume?: number, NetWeight?: number, OutboundShipmentId?: number, PackagingId?: number, VolumeUomId?: number, WeightUomId?: number, Lot?: { Id?: number, VendorLotId?: number, Material?: { Id?: number, PackagingLookups?: { PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number }[] } }, LicensePlate?: { Id?: number, LocationId?: number, ShipmentId?: number, ShippingContainerId?: number, WarehouseId?: number } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serialNumberId)) {
      missingRequiredInParams.push('\'serialNumberId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      serialNumberId: number    }) {
        if(isNil(inParams.serialNumberId)) {
          return true; 
        }
      return false;
    }
}
