import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './SalesOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class SalesOrders_transfer_licenseplates_to_new_salesorder_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { licenseplateIds: number[] }): Promise<{ reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateIds)) {
      missingRequiredInParams.push('\'licenseplateIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

