import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { WarehouseTransfers_homeComponent } from './WarehouseTransfers.home.component';
import { WarehouseTransfers_outbound_bill_of_lading_reportComponent } from './WarehouseTransfers.outbound_bill_of_lading_report.component';
import { WarehouseTransfers_outbound_pick_slip_reportComponent } from './WarehouseTransfers.outbound_pick_slip_report.component';


@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public WarehouseTransfers: WarehouseTransfers_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'WarehouseTransfers',
        referenceName: 'WarehouseTransfers_home',
        component: WarehouseTransfers_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      WarehouseTransfers_homeComponent,
      'WarehouseTransfers',
      mode,
      dialogSize
    )
  }
  public openoutbound_bill_of_lading_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Bill of lading',
        referenceName: 'WarehouseTransfers_outbound_bill_of_lading_report',
        component: WarehouseTransfers_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_bill_of_lading_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      WarehouseTransfers_outbound_bill_of_lading_reportComponent,
      'Bill of lading',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoutbound_pick_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'WarehouseTransfers_outbound_pick_slip_report',
        component: WarehouseTransfers_outbound_pick_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_pick_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      WarehouseTransfers_outbound_pick_slip_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'WarehouseTransfers_home') {
      const title = 'WarehouseTransfers';
      const component = WarehouseTransfers_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_outbound_bill_of_lading_report') {
      const title = 'Bill of lading';
      const component = WarehouseTransfers_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_outbound_pick_slip_report') {
      const title = 'Pick Slip';
      const component = WarehouseTransfers_outbound_pick_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
