import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './AsnOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class AsnOrders_create_asn_serial_numbers_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { serialNumbers: { Archived?: boolean, ChainHead?: number, DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LicensePlateId?: number, LotId?: number, NetVolumeValue?: number, NetWeightValue?: number, Notes?: string, OutboundShipmentId?: number, PackagingId?: number, StatusId?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, LookupCode?: string, MaterialId?: number }[] }): Promise<{ reasons?: string[], serialNumberIds?: number[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serialNumbers)) {
      missingRequiredInParams.push('\'serialNumbers\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

