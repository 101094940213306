<div matAutocompleteOrigin
     [ngStyle]="styles?.style"
     [ngClass]="styles?.classes"
     class="datex-selectcontainer {{disabled ? 'readonly' : ''}}"
     #origin="matAutocompleteOrigin"
     data-cy="selectBox">
  <input #trigger
         class="select-input"
         type="text"
         spellcheck="false"
         matInput
         [placeholder]="!hasSelectedItem ? placeholder : ''"
         [matAutocomplete]="auto1"
         [matAutocompleteConnectedTo]="origin"
         matAutocompleteDisabled="true"
         [disabled]="disabled"
         (keyup)="onKeyUp($event)"
         (keydown)="onKeyDown($event)"
         (focus)="onFocus($event)"
         (blur)="onBlur($event)"
         (click)="onClick($event)"
         panelClass="dropdown-below"
         data-cy="selectBox-input"
         [matTooltip]="tooltip"
         matTooltipClass="datex-control-tooltip">
  <div [hidden]="!hasSelectedItem || disabled"
       class="select-button clear"
       (click)="clearSelectedItem()"
       data-cy="selectBox-button-clear">
    <i class="icon icon-ic_fluent_dismiss_20_regular select-button-icon"></i>
  </div>
  <div [hidden]="disabled"
       (click)="togglePanel($event)"
       class="select-button"
       data-cy="selectBox-button-toggle">
    <i class="icon icon-ic_fluent_chevron_down_20_regular select-button-icon"></i>
  </div>
</div>
<mat-autocomplete #auto1="matAutocomplete"
                  (opened)="opened()"
                  (closed)="closed()"
                  [displayWith]="displayFn.bind(this)"
                  [autoActiveFirstOption]="true"
                  classList="datex-dropdownpanel"
                  class="datex-dropdownpanel">
  <mat-option *ngFor="let item of filteredOptions"
              (click)="optionSelected(item)"
              [class.mat-selected]="item.key === value"
              [value]="item"
              [disabled]="item.disabled"
              [matTooltip]="item?.name"
              matTooltipClass="datex-control-tooltip"
              class="datex-dropdownoption gek"
              data-cy="selectBox-option">
    {{item ? item.name : undefined}}
  </mat-option>
  <mat-option *ngIf="newItemLabel"
              (click)="onNewItemClicked()"
              class="dropdown-action"
              data-cy="selectBox-option-newItem">
    {{newItemLabel}}
  </mat-option>
</mat-autocomplete>