import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_vector_labelServiceInParams {
  jobId?: string, shippingContainerLookup?: string}

interface IReports_custom_vector_labelServiceData {
  custom_vector_label?: { result?: { jobId?: string, tote?: string } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_vector_labelService extends ReportBaseService<IReports_custom_vector_labelServiceInParams, IReports_custom_vector_labelServiceData> {

  protected reportReferenceName = 'custom_vector_label';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_vector_labelServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_vector_labelServiceInParams): Promise<IReports_custom_vector_labelServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_vector_labelServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_vector_labelServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        jobId:  $report.inParams.jobId ,
        tote:  $report.inParams.shippingContainerLookup 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_vector_label.get(dsParams);
      
      data.custom_vector_label = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
