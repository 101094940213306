import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_sales_order_lines_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, fullTextSearch?: string, has_children?: boolean }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, HasChildren?: boolean, LicensePlateId?: number, LotId?: number, MaterialId?: number, NetWeight?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, SerialNumberId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[], LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, SerialNumber?: { LookupCode?: string, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } } }, ParentOrderLine?: { LotId?: number, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, InventoryMeasurementUnit?: { Id?: number, ShortName?: string } }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, fullTextSearch?: string, has_children?: boolean, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, HasChildren?: boolean, LicensePlateId?: number, LotId?: number, MaterialId?: number, NetWeight?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, SerialNumberId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[], LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, SerialNumber?: { LookupCode?: string, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } } }, ParentOrderLine?: { LotId?: number, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, InventoryMeasurementUnit?: { Id?: number, ShortName?: string } }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, fullTextSearch?: string, has_children?: boolean, $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, HasChildren?: boolean, LicensePlateId?: number, LotId?: number, MaterialId?: number, NetWeight?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, SerialNumberId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[], LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, SerialNumber?: { LookupCode?: string, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } } }, ParentOrderLine?: { LotId?: number, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, InventoryMeasurementUnit?: { Id?: number, ShortName?: string } }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, fullTextSearch?: string, has_children?: boolean    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
