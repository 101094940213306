import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_transaction_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { transaction_id: string, log_ids?: string[], message_ids?: string[] }): Promise<{ result?: { transaction_id?: string, ingress_id?: string, egress_id?: string, integration_name?: string, status?: string, direction?: string, reference?: string, sender?: string, receiver?: string, payload?: string, transaction_type?: string, environment?: string, process?: string, level?: number, created_on?: string, created_by?: string, log_notes?: string, message_notes?: string, log_details?: { field?: string, content?: string }[], message_details?: { field?: string, content?: string }[] } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.transaction_id)) {
      missingRequiredInParams.push('\'transaction_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      transaction_id: string, log_ids?: string[], message_ids?: string[]    }) {
        if(isNil(inParams.transaction_id)) {
          return true; 
        }
      return false;
    }
}
