import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Cartonization_ds_get_shipment_lines_by_shipmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number, shipmentLineId?: number }): 
  Promise<{ result: { Id?: number, ActualAmount?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, ExpectedAmount?: number, ExpectedPackagedAmount?: number, ExpectedPackagedId?: number, ExpectedShippingContainerId?: number, LineNumber?: number, OrderId?: number, ShipmentId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, OrderLine?: { LineNumber?: number, MaterialId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId: number, shipmentLineId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualAmount?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, ExpectedAmount?: number, ExpectedPackagedAmount?: number, ExpectedPackagedId?: number, ExpectedShippingContainerId?: number, LineNumber?: number, OrderId?: number, ShipmentId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, OrderLine?: { LineNumber?: number, MaterialId?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId: number, shipmentLineId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualAmount?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, ExpectedAmount?: number, ExpectedPackagedAmount?: number, ExpectedPackagedId?: number, ExpectedShippingContainerId?: number, LineNumber?: number, OrderId?: number, ShipmentId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, OrderLine?: { LineNumber?: number, MaterialId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentId: number, shipmentLineId?: number    }) {
        if(isNil(inParams.shipmentId)) {
          return true; 
        }
      return false;
    }
}
