import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_get_serial_number_by_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { serial_number_id: number }): 
  Promise<{ result: { Id?: number, Archived?: boolean, ChainHead?: number, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NetVolume?: number, NetWeight?: number, Notes?: string, OriginalChainHead?: number, OutboundShipmentId?: number, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serial_number_id)) {
      missingRequiredInParams.push('\'serial_number_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      serial_number_id: number    }) {
        if(isNil(inParams.serial_number_id)) {
          return true; 
        }
      return false;
    }
}
