import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_outbound_pick_slip_by_orderId_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, Order?: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } }, Shipment?: { Id?: number, ExpectedDate?: string, LookupCode?: string, ShippedDate?: string, WaveId?: number, Wave?: { Id?: number, Warehouse?: { Name?: string } }, Carrier?: { Name?: string, ShortName?: string }, Account?: { Name?: string } }, PickSlip?: { Id?: number, LookupCode?: string }, Lot?: { LookupCode?: string }, OrderLine?: { LineNumber?: number, Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, ExpectedSourceLocation?: { Name?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number }, OrderAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number }, OwnerAddress?: { Id?: number, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, Order?: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } }, Shipment?: { Id?: number, ExpectedDate?: string, LookupCode?: string, ShippedDate?: string, WaveId?: number, Wave?: { Id?: number, Warehouse?: { Name?: string } }, Carrier?: { Name?: string, ShortName?: string }, Account?: { Name?: string } }, PickSlip?: { Id?: number, LookupCode?: string }, Lot?: { LookupCode?: string }, OrderLine?: { LineNumber?: number, Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, ExpectedSourceLocation?: { Name?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number }, OrderAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number }, OwnerAddress?: { Id?: number, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, Order?: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } }, Shipment?: { Id?: number, ExpectedDate?: string, LookupCode?: string, ShippedDate?: string, WaveId?: number, Wave?: { Id?: number, Warehouse?: { Name?: string } }, Carrier?: { Name?: string, ShortName?: string }, Account?: { Name?: string } }, PickSlip?: { Id?: number, LookupCode?: string }, Lot?: { LookupCode?: string }, OrderLine?: { LineNumber?: number, Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, ExpectedSourceLocation?: { Name?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number }, OrderAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number }, OwnerAddress?: { Id?: number, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
