import { Inject, Injectable, Injector } from '@angular/core';


import { Reports_custom_get_sscc_label_report_flowService } from './Reports.flow.index';

import { $frontendTypes } from './Reports.frontend.types'

@Injectable({ providedIn: 'root' })
export class Reports_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_get_sscc_label_report_flow: Reports_custom_get_sscc_label_report_flowService;
  public async custom_get_sscc_label_report_flow(inParams: { shipping_container_id: number, format?: string }): Promise< { reportData?: Blob }> {
    if(!this._custom_get_sscc_label_report_flow) {
      this._custom_get_sscc_label_report_flow = this.injector.get(Reports_custom_get_sscc_label_report_flowService);
    }
    return this._custom_get_sscc_label_report_flow.run(inParams);
  }
   
   

   
}
