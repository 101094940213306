import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Reports.frontend.types'

@Injectable({ providedIn: 'root' })
export class Reports_custom_get_sscc_label_report_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { shipping_container_id: number, format?: string }): Promise<{ reportData?: Blob }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipping_container_id)) {
      missingRequiredInParams.push('\'shipping_container_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    const blobOptions: any = {
      ...options,
      observe: 'response',
      responseType: 'blob'
    };
    const response = await this.utils.http.post<HttpResponse<Blob>>(url, body, blobOptions);
    const blobParamValue = response.status === 200 ? this.utils.http.httpClientResponseToBlob(response) : null;
    return { reportData: blobParamValue };
    
  }

}

